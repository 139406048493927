import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import firebase from '../firebase';
import { baseURL } from './baseURL';
import SweetAlert from 'react-bootstrap-sweetalert';

const Modifiers = () => {
    const nav = useNavigate();
    const [modifiers, setModifiers] = useState([]);
    const [displayModifiers, setDisplayModifiers] = useState([]);
    const [alert, setAlert] = useState(false);
    const [alert2, setAlert2] = useState(false);
    const [loading, setLoading] = useState(true);
    const [deleteId, setDeleteId] = useState('');

    useEffect(() => {
        getModifiers();
    }, [])

    const getModifiers = () => {
        firebase.firestore().collection('restaurant_modifiers').where('restaurant_id', '==', localStorage.getItem('CB_settings_ID')).get().then((querySnapshot) => {
            let arr = [];
            querySnapshot.forEach((doc) => {
                arr.push({ ...doc.data() })
            })
            setModifiers(arr);
            setDisplayModifiers(arr);
            setLoading(false);
        })
    }

    const handleSearch = (e) => {
        if (e.target.value) {
            let search = e.target.value;
            let arr = modifiers?.filter(
                (elem) =>
                    elem.group_name.toLowerCase().includes(search.toLowerCase())
            );
            setDisplayModifiers(arr);
        } else {
            setDisplayModifiers(modifiers)
        }
    }

    const DeleteModifier = async () => {
        await firebase.firestore().collection('restaurant_modifiers').doc(deleteId).delete();
        setDeleteId('');
        setAlert(false);
        setAlert2(true);
        getModifiers();
    }

    const handleDeleteModifier = (id) => {
        setDeleteId(id);
        setAlert(true);
    }
    return (
        <>
            <div className="customers-product-order" style={{ overflow: 'auto' }}>
                <div className="customers-product-order-search">
                    <div className="customers-product-order-input">
                        <input type="text" className="form-control" placeholder="search modifier" onChange={handleSearch} />
                    </div>
                    <div className="customers-product-top-btn">
                        <Link to="/manage-modifier" className='btn btn-success'>Add New</Link>
                    </div>
                </div>
                <div className="customers-product-order-list">
                    <div className="table-responsive">
                        <table className="table mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">Sr. No.</th>
                                    <th scope="col">Group Name</th>
                                    <th scope="col">No. of Modifiers</th>
                                    <th scope="col">Modifier Enabled</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!loading ?
                                    displayModifiers?.length > 0 ? displayModifiers.map((elem, index) =>
                                        <tr key={index} style={{ cursor: 'pointer' }}>
                                            <td>{index + 1}</td>
                                            <td>{elem?.group_name}</td>
                                            <td>{elem?.modifiers?.length}</td>
                                            <td>{elem?.is_enable ? "Yes" : "No"}</td>
                                            <td>
                                                <div className="dropdown">
                                                    <button className="btn p-0" id="dropdownMenuLink"
                                                        data-bs-toggle="dropdown" aria-expanded="false">
                                                        <img src={`${baseURL}assets/images/more.png`} alt="" className="img-fluid" />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <li><Link to='/manage-modifier' className="dropdown-item" state={{ id: elem.modifier_id }}>Edit Modifier</Link></li>
                                                        <li><a className="dropdown-item" onClick={() => handleDeleteModifier(elem.modifier_id)} style={{ cursor: "pointer" }}>Delete Modifier</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    ) : <tr><td colSpan="5"><strong>No Record Found</strong></td></tr>
                                    : <tr><td colSpan="5">Loading...</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {alert === true ? <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                title="Are you sure?"
                onConfirm={DeleteModifier}
                onCancel={() => { setAlert(false); setDeleteId(''); }}
            >
                You will not be able to recover this modifier!
            </SweetAlert> : ''
            }
            {alert2 === true ? <SweetAlert
                success
                title="Modifier Deleted Successfully!"
                onConfirm={() => { setAlert2(false); getModifiers(); }}
            />
                : ''}
        </>

    )
}

export default Modifiers