import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase, { storage } from '../firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { toast, ToastContainer } from 'react-toastify';

const Profile = () => {
    const nav = useNavigate();
    const [resto, setResto] = useState({});
    const [passDetails, setPassDetails] = useState({
        old: "",
        new: "",
        confirm: ""
    });
    const [showPass, setShowPass] = useState({
        old: false,
        new: false,
        confirm: false
    })
    const [loading, setLoading] = useState(true);
    const [imgErr, setImgErr] = useState('');
    const [progress, setProgress] = useState('');
    const [disable, setDisable] = useState(false);
    const [disable2, setDisable2] = useState(false);
    const [profileErr, setProfileErr] = useState({});
    const [passErr, setPassErr] = useState({});

    useEffect(() => {
        getRestaurantDetails();
    }, [])

    const getRestaurantDetails = () => {
        firebase.firestore().collection('restaurants').doc(localStorage.getItem('CB_settings_ID')).get().then(doc => {
            setResto(doc.data());
            // setLoading(false);
        })
    }

    const handleImage = (e) => {
        let img = e.target.files[0];
        if (img.type === 'image/jpeg' || img.type === 'image/jpg' || img.type === 'image/png') {
            setImgErr('');
            setProgress(0);
            console.log(img);
            const storageRef = ref(storage, `restaurant_images/${Date.now()}`);
            const uploadTask = uploadBytesResumable(storageRef, img);

            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress =
                        Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgress(progress);
                },
                (error) => {
                    alert(error);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then(url => {
                        setResto({ ...resto, restaurant_image: url });
                        setProgress('')
                    })
                }
            )
            // setImage(img);
        } else {
            setImgErr('Incorrect Image Format.')
        }
    }

    const validateProfile = () => {
        let input = resto;
        let isValid = true;
        let err = {};

        if (!input['restaurant_name']) {
            isValid = false;
            err['name_err'] = 'Please enter name'
        }

        if (!input['restaurant_email']) {
            isValid = false;
            err['email_err'] = 'Please enter email'
        }

        setProfileErr(err);
        return isValid;
    }

    const handleUpdateProfile = (e) => {
        if (validateProfile()) {
            setDisable(true);
            if (resto.restaurant_image) {
                firebase.firestore().collection('restaurants').doc(localStorage.getItem('CB_settings_ID')).update({
                    restaurant_name: resto.restaurant_name,
                    restaurant_email: resto.restaurant_email,
                    restaurant_image: resto.restaurant_image
                }).then(() => {
                    setDisable(false);
                    toast.success("Profile Updated");
                    setProfileErr({});
                    setTimeout(() => {
                        window.location.reload()
                    }, [1000]);
                })
            } else {
                firebase.firestore().collection('restaurants').doc(localStorage.getItem('CB_settings_ID')).update({
                    restaurant_name: resto.restaurant_name,
                    restaurant_email: resto.restaurant_email
                }).then(() => {
                    setDisable(false);
                    toast.success("Profile Updated");
                    setProfileErr({});
                    setTimeout(() => {
                        window.location.reload()
                    }, [1000]);
                })
            }
        }
    }

    const validatePassword = () => {
        let input = passDetails;
        let isValid = true;
        let err = {};

        if (!input['old']) {
            isValid = false;
            err['old_err'] = 'Please enter old password';
        } else if (input['old'] !== resto.restaurant_password) {
            isValid = false;
            err['old_err'] = 'Incorrect old password';
        }
        if (!input['new']) {
            isValid = false;
            err['new_err'] = 'Please enter new password';
        } else if (input['new'] === input['old']) {
            isValid = false;
            err['new_err'] = 'New password should be different from old one';
        }
        if (!input['confirm']) {
            isValid = false;
            err['confirm_err'] = 'Please enter confirm password';
        } else if (input['new'] !== input['confirm']) {
            isValid = false;
            err['confirm_err'] = `Password doesn't match`;
        }

        setPassErr(err);
        return isValid;
    }

    const handleUpdatePassword = (e) => {
        if (validatePassword()) {
            setDisable2(true);
            firebase.firestore().collection('restaurants').doc(localStorage.getItem('CB_settings_ID')).update({
                restaurant_password: passDetails.new,
            }).then(() => {
                setDisable2(false);
                toast.success("Password Updated");
                setPassErr({});
            })
        }
    }
    return (
        <>
            <ToastContainer />
            <div className="food-add-item customers-product-order" style={{ overflowY: 'auto' }}>
                <div className="food-items-form">
                    <div className="row">
                        <div className="col col-12 col-md-12 col-lg-6 col-xl-6">
                            <div className="food-item-details">
                                <div className="food-details-heading">
                                    <h4 className="food-details-title">Edit Profile</h4>
                                </div>
                                <div className="food-details-form">
                                    <div className="food-details-input">
                                        <label className="form-label">Restaurant Name</label>
                                        <div>
                                            <input type="text" className="form-control" value={resto.restaurant_name} onChange={(e) => setResto({ ...resto, restaurant_name: e.target.value })} />
                                            <div className='error'>{profileErr.name_err}</div>
                                        </div>
                                    </div>
                                    <div className="food-details-input">
                                        <label className="form-label">Restaurant Email</label>
                                        <div>
                                            <input type="text" className="form-control" readOnly value={resto.restaurant_email} onChange={(e) => setResto({ ...resto, restaurant_email: e.target.value })} />
                                            <div className='error'>{profileErr.email_err}</div>
                                        </div>
                                    </div>
                                    <div className="food-details-input">
                                        <label className="form-label">Restaurant Image</label>
                                        <div >
                                            <input type="file" className="form-control" onChange={handleImage} />
                                            <div className='error'>{imgErr}</div>
                                        </div>
                                        {resto.restaurant_image ?
                                            <img src={resto.restaurant_image} alt='resto' style={{ width: "60px", height: "60px", objectFit: "cover" }} />
                                            : ""}
                                    </div>
                                    <button disabled={disable} className="btn btn-success" onClick={handleUpdateProfile}>{disable ? 'Processing' : 'Update Profile'}</button>
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-12 col-lg-6 col-xl-6">
                            <div className="food-item-details">
                                <div className="food-details-heading">
                                    <h4 className="food-details-title">Change Password</h4>
                                </div>
                                <div className="food-details-form">
                                    <div className="food-details-input">
                                        <label className="form-label">Old Password</label>
                                        <div className="input-group">
                                            <input type={showPass.old === false ? "password" : "text"} className="form-control" onChange={(e) => setPassDetails({ ...passDetails, old: e.target.value })} />
                                            <span class="input-group-text" style={{ cursor: "pointer" }} onClick={() => setShowPass({ ...showPass, old: !showPass.old })}>
                                                {showPass.old === false ?
                                                    <i class="fa fa-eye" />
                                                    : <i class="fa fa-eye-slash" />}
                                            </span>
                                            <div className='error'>{passErr.old_err}</div>
                                        </div>
                                    </div>
                                    <div className="food-details-input">
                                        <label className="form-label">New Password</label>
                                        <div className="input-group">
                                            <input type={showPass.new === false ? "password" : "text"} className="form-control" onChange={(e) => setPassDetails({ ...passDetails, new: e.target.value })} />
                                            <span class="input-group-text" style={{ cursor: "pointer" }} onClick={() => setShowPass({ ...showPass, new: !showPass.new })}>
                                                {showPass.new === false ?
                                                    <i class="fa fa-eye" />
                                                    : <i class="fa fa-eye-slash" />}
                                            </span>
                                            <div className='error'>{passErr.new_err}</div>
                                        </div>
                                    </div>
                                    <div className="food-details-input">
                                        <label className="form-label">Confirm Password</label>
                                        <div className="input-group">
                                            <input type={showPass.confirm === false ? "password" : "text"} className="form-control" onChange={(e) => setPassDetails({ ...passDetails, confirm: e.target.value })} />
                                            <span class="input-group-text" style={{ cursor: "pointer" }} onClick={() => setShowPass({ ...showPass, confirm: !showPass.confirm })}>
                                                {showPass.confirm === false ?
                                                    <i class="fa fa-eye" />
                                                    : <i class="fa fa-eye-slash" />}
                                            </span>
                                            <div className='error'>{passErr.confirm_err}</div>
                                        </div>
                                    </div>
                                    <button disabled={disable2} className="btn btn-success" onClick={handleUpdatePassword}>{disable2 ? 'Processing' : 'Update Password'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile