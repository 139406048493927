import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import firebase from '../firebase';
import SweetAlert from 'react-bootstrap-sweetalert';
import { baseURL } from './baseURL';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
const CategoryItems = () => {
    const { id, status } = useParams();
    const [resto, setResto] = useState({});
    const [deleteId, setDeleteId] = useState('');
    const [itemsList, setItemList] = useState([]);
    const [categories, setCategories] = useState([]);
    const [displayItems, setDisplayItems] = useState([]);
    const rows = [5, 10, 20, 30];;
    const [itemsPerPage, setItemsPerPage] = useState(rows[0]);
    const [currentPage, setCurrentPage] = useState(1);
    const [alert, setAlert] = useState(false);
    const [alert2, setAlert2] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getCategoryList();
        getProfile();
    }, [])
    const getProfile = () => {
        firebase.firestore().collection('restaurants').doc(localStorage.getItem('CB_settings_ID')).get().then(doc => {
            setResto(doc.data());
            // setLoading(false);
        })
    }
    const getCategoryList = () => {
        firebase.firestore().collection('categories').where('is_enable', '==', true)
            .get().then(querySnapshot => {
                let arr = [];
                querySnapshot.forEach(doc => {
                    arr.push({ cat_id: doc.id, cat_name: doc.data().category_name, cat_image: doc.data().category_image })
                });
                setCategories(arr.reverse());
                getItemList(arr.reverse());
            })
    }

    const getItemList = async (cat_arr) => {
        //setLoading(true) 
        console.log(status);
        var qry = firebase.firestore().collection('items').where('restaurant_id', '==', localStorage.getItem('CB_settings_ID')).where('category_id', '==', id);
        if (status == "true")
            qry = qry.where('item_available_in_stock', '==', true);
        else
            qry = qry.where('item_available_in_stock', '!=', true);
        await qry.get().then(snapshot => {
            let arr = [];
            let index = 0;
            snapshot.forEach((doc) => {
                let cat_name = '';
                cat_arr.map((elem) => {
                    if (elem.cat_id === doc.data().category_id) {
                        cat_name = elem.cat_name
                    }
                })
                index += 1;
                arr.push({ ...doc.data(), category_name: cat_name, index, item_id: doc.id })
            })
            setItemList(arr);
            setLoading(false);
        })
    }

    useEffect(() => {
        setDisplayItems(itemsList)
    }, [itemsList]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    //const currentItems = displayItems.slice(indexOfFirstItem, indexOfLastItem);
    const currentItems = displayItems;

    const lastPageNumber = Math.ceil(displayItems.length / itemsPerPage);

    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(displayItems.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    const paginate = (num) => {
        //setCurrentPage(num);
    }

    const handleSearch = (e) => {
        if (e.target.value) {
            let search = e.target.value;
            let arr = itemsList.filter(
                (elem) =>
                    elem.item_name.toLowerCase().includes(search.toLowerCase()) ||
                    elem.category_name.toLowerCase().includes(search.toLowerCase())
            );
            let arr2 = [];
            let index = 0;
            arr.map((elem) => {
                index += 1;
                arr2.push({ ...elem, index });
            })
            setDisplayItems(arr2);
        } else {
            setDisplayItems(itemsList)
        }
    }

    const add3Dots = (string, limit) => {
        var dots = "...";
        if (string.length > limit) {
            string = string.substring(0, limit) + dots;
        }
        return string;
    }

    const handleDeleteItem = (id) => {
        console.log(id);
        setDeleteId(id);
        setAlert(true);
    }

    const DeleteItem = () => {
        console.log(deleteId);
        firebase.firestore().collection('items').doc(deleteId).delete().then(() => {
            setDeleteId('');
            setAlert(false);
            setAlert2(true);
            getCategoryList();
        })
    }
    return (
        <>
            <div className="customers-product-order" style={{ overflowY: "auto" }}>
                <div className="customers-product-order-search">
                    <div className="customers-product-order-input">
                        <input type="text" className="form-control" placeholder="search items" onChange={handleSearch} />
                    </div>
                    <div className="customers-product-top-btn">
                        <button className="btn btn-success" style={{ marginRight: "10px" }}>Import CSV</button>
                        {/* <Link to='/add-item' className="btn btn-success">Add New</Link> */}
                    </div>
                </div>
                <div className="customers-product-order-list">
                    <div className="table-responsive">
                        <DragDropContext>
                            <table className="table mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No.</th>
                                        <th scope="col">Item Name</th>
                                        <th scope="col">Item Image</th>
                                        <th scope="col">Item Category</th>
                                        <th scope="col">Item Price</th>
                                    </tr>
                                </thead>
                                {
                                    !loading ?
                                        (
                                            currentItems.length > 0 ? currentItems.map((elem, index) =>
                                                <tr key={index}>
                                                    <td>{elem.index}</td>
                                                    <td>{elem.item_name}</td>
                                                    <td>{elem.item_image ? <img src={elem.item_image} alt='item-img' style={{ width: "70px", height: "50px", objectFit: "cover" }} /> : <img src={`${baseURL}assets/images/placeholderImg1.png`} alt='item-img' style={{ width: "50px", height: "50px", objectFit: "cover" }} />}</td>
                                                    <td>{elem.category_name}</td>
                                                    <td className="product-order-spending"> <span>{resto?.currency_symbol ?? '$'} {elem.item_price}</span></td>
                                                    <td>
                                                        <div className="dropdown">
                                                            <button className="btn p-0" id="dropdownMenuLink"
                                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                                <img src={`${baseURL}assets/images/more.png`} alt="" className="img-fluid" />
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                <li><Link to='/edit-item' className="dropdown-item" state={{ item_id: elem.item_id }}>Edit Item</Link></li>
                                                                <li><a className="dropdown-item" onClick={() => handleDeleteItem(elem.item_id, elem.category_id)} style={{ cursor: "pointer" }}>Delete Item</a></li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) : <tr><td colSpan="5"><strong>No Record Found</strong></td></tr>
                                        )
                                        : <tr><td colSpan="5">Loading...</td></tr>
                                }
                            </table>
                        </DragDropContext>
                    </div>
                </div>
                {currentItems.length > 0 ? <div className="product-order-pages">
                    <h6 className="product-order-page-title">Showing {currentItems[currentItems.length - 1].index} of {displayItems.length} Data</h6>
                    <nav aria-label="Page navigation example">
                        <ul className="pagination">
                            <li className="page-item">
                                <button className="page-link-btn page-link-prev" disabled={currentPage === 1} onClick={() => paginate(currentPage - 1)}>Prev</button>
                            </li>
                            <li className="page-item ">
                                <a className="page-link page-link-number active">{currentPage}</a>
                            </li>
                            <li className="page-item">
                                <button className="page-link-btn page-link-next" disabled={currentPage === lastPageNumber} onClick={() => paginate(currentPage + 1)}>Next</button>
                            </li>
                        </ul>
                    </nav>
                </div> : ''}
            </div>
            {alert === true ? <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                title="Are you sure?"
                onConfirm={DeleteItem}
                onCancel={() => { setAlert(false); setDeleteId(''); }}
            >
                You will not be able to recover this item!
            </SweetAlert> : ''
            }
            {alert2 === true ? <SweetAlert
                success
                title="Item Deleted Successfully!"
                onConfirm={() => { setAlert2(false); getCategoryList(); }}
            />
                : ''}
        </>
    )
}

export default CategoryItems