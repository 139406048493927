import React, { useState } from 'react';
import { useEffect } from 'react';
import firebase from '../firebase';
import moment from 'moment/moment';
import * as Excel from "exceljs/dist/exceljs.min.js";
import { saveAs } from 'file-saver'
import { toast, ToastContainer } from 'react-toastify';

const SalesReports = () => {
    const [dates, setDates] = useState({
        start: '',
        end: ''
    });
    const [error, setError] = useState({});
    const [status, setStatus] = useState('Generate');
    const [disable, setDisable] = useState(false);
    const [result, setResult] = useState('');
    const [deliveryBoyList, setDeliveryBoyList] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [finalArr, setFinalArr] = useState([]);

    useEffect(() => {
        getDeliveryBoyList();
        getUsersList();
        getStatusList();
    }, []);

    const getDeliveryBoyList = () => {
        firebase.firestore().collection('delivery_boys').get().then((snapshot) => {
            let arr = [];
            snapshot.forEach((doc) => {
                arr.push({ d_boy_id: doc.id, d_boy_name: doc.data().d_boy_name });
            })
            setDeliveryBoyList(arr);
        })
    }

    const getUsersList = () => {
        firebase.firestore().collection('users').get().then((snapshot) => {
            let arr = [];
            snapshot.forEach((doc) => {
                if (doc.data().user_member_id) {
                    arr.push({ user_id: doc.id, user_name: doc.data().user_name, user_member_id: doc.data().user_member_id });
                } else {
                    arr.push({ user_id: doc.id, user_name: doc.data().user_name, user_member_id: 'N/A' });
                }
            })
            setUsersList(arr);
        })
    }

    const getStatusList = () => {
        firebase.firestore().collection('order_status').get().then((snapshot) => {
            let arr = [];
            snapshot.forEach((doc) => {
                arr.push({ ...doc.data() });
            })
            setStatusList(arr);
        })
    }

    const validateDates = () => {
        let isValid = true;
        let err = {};
        let input = dates;

        if (!input['start']) {
            isValid = false;
            err['start_err'] = "Please select from date"
        }

        if (!input['end']) {
            isValid = false;
            err['end_err'] = "Please select to date"
        }

        setError(err);
        setResult('');
        return isValid;
    }
    const downloadExcel = (async () => {
        let start_date = new Date(dates.start);
        let end_date = new Date(dates.end);
        let wb = new Excel.Workbook();
        const ws = wb.addWorksheet();
        ws.columns = [
            { header: 'Order Date', key: 'order_date' },
            { header: 'Customer Name', key: 'customer_name' },
            { header: 'Member ID', key: 'member_id' },
            { header: 'Item Name', key: 'item_name' },
            { header: 'Item Detail', key: 'item_detail' },
            { header: 'Subtotal', key: 'subtotal' },
            { header: 'Status', key: 'status' },
            { header: 'Tip', key: 'tip' },
            { header: 'Delivery Driver', key: 'driver' },
            { header: 'Sales tax', key: 'tax' },
            { header: 'Total', key: 'total' }
        ];
        ws.columns = [{ width: 25 }, { width: 15 }, { width: 15 }, { width: 30 }, { width: 100 }, { width: 10 }, { width: 10 }, { width: 10 }, { width: 10 }, { width: 10 }, { width: 10 }]
        ws.getCell('A1').font = { bold: true };
        ws.getCell('B1').font = { bold: true };
        ws.getCell('C1').font = { bold: true };
        ws.getCell('D1').font = { bold: true };
        ws.getCell('E1').font = { bold: true };
        ws.getCell('F1').font = { bold: true };
        ws.getCell('G1').font = { bold: true };
        ws.getCell('H1').font = { bold: true };
        ws.getCell('I1').font = { bold: true };
        ws.getCell('J1').font = { bold: true };
        ws.getCell('K1').font = { bold: true };

        ws.addRows(finalArr);
        const buf = await wb.xlsx.writeBuffer()
        saveAs(new Blob([buf]), `Report ${moment.unix(start_date / 1000).format('Do MMM YYYY')}-${moment.unix(end_date / 1000).format('Do MMM YYYY')}.xlsx`)
        setDisable(false);
        setStatus('Generate');
    });

    const handleGenerate = (async () => {
        if (validateDates()) {
            setDisable(true);
            // let start_date = new Date(dates.start);
            // let end_date = new Date(dates.end);
            let start_date = moment(dates.start).unix() * 1000;
            let end_date = moment(dates.end).unix() * 1000;
            const rows_arr = [];

            await firebase.firestore().collection('orders').where('restaurant_id', '==', localStorage.getItem('CB_settings_ID')).get().then(async (querySnapshot) => {
                let arr = [];
                setStatus('Generating ....');
                await querySnapshot.forEach((doc) => {
                    // if (doc.data().order_date >= start_date && doc.data().order_date <= end_date) {
                    //     arr.push({ ...doc.data() });
                    // }
                    if (parseInt(doc.data().order_date) >= start_date && parseInt(doc.data().order_date) <= end_date) {
                        arr.push({ ...doc.data() });
                    }
                })
                if (arr.length !== 0) {

                    arr.map(async (elem) => {
                        let order_date = moment.unix(elem.added_on / 1000).format('Do, MMMM YYYY');
                        let user_name = '';
                        let member_id = '';
                        let order_status = '';
                        let d_boy_name = 'N/A';
                        usersList.map((user) => { if (user.user_id === elem.user_id) { user_name = user.user_name; member_id = user.user_member_id; } });
                        statusList.map((stat) => { if (parseInt(stat.order_status) === parseInt(elem.order_status)) { order_status = stat.order_status_label; } });
                        if (elem.d_boy_id) {
                            deliveryBoyList.map((boy) => {
                                if (boy.d_boy_id === elem.d_boy_id) {
                                    d_boy_name = boy.d_boy_name;
                                }
                            });
                        }
                        await firebase.firestore().collection('orders').doc(elem.order_id).collection('items').get().then(async (snapshot) => {
                            let item_arr = [];
                            let item_detail = [];
                            let index = 0;
                            snapshot.forEach((doc) => {
                                index += 1;
                                item_arr.push(doc.data().item_name);
                                item_detail.push(`Item ${index} : ${doc.data().item_name}-(Qnt:${doc.data().item_quantity},Price:${parseFloat(doc.data().item_price).toFixed(2)})`);
                            });
                            await firebase.firestore().collection('orders').doc(elem.order_id).collection('item_modifier').get().then((querySnapshot) => {
                                if (querySnapshot.docs.length > 0) {
                                    let indexs = 0;
                                    querySnapshot.forEach((document) => {
                                        let grp_arr = [];
                                        document.data().cart_modifier_groups.map((grp) => {
                                            grp.modifiers.map((mod) => {
                                                grp_arr.push(`Group Name:${grp.group_name} - (${mod.modifier_name},Price:${parseFloat(mod.set_price).toFixed(2)})`);
                                            });
                                        });
                                        item_detail[indexs] = item_detail[indexs] + `[Modifiers-(${grp_arr.join(')(')})]`;
                                        indexs += 1;
                                    });
                                }
                            });
                            console.log("row");
                            rows_arr.push({ order_date, customer_name: user_name, member_id, item_name: item_arr.join(','), item_detail: item_detail.join(','), subtotal: parseFloat(elem.items_total).toFixed(2), status: order_status, tip: parseFloat(elem.tip).toFixed(2), driver: d_boy_name, tax: parseFloat(elem.sales_tax).toFixed(2), total: parseFloat(elem.grand_total).toFixed(2) });
                        });
                    });
                    setFinalArr(rows_arr);
                    setTimeout(() => {
                        setDisable(false);
                        setStatus('Download');
                        toast.success('Report Generated Successfully. Download Now !!')
                    }, [3000])
                } else {
                    setDisable(false);
                    setStatus('Generate');
                    setResult('No orders found across selected dates.')
                }
            })
        }

    });
    return (
        <>
            <ToastContainer />
            <div className="food-add-item customers-product-order" data-scrollbar>
                <h2 className="food-add-item-title">Sales Report </h2>
                <div className="s-report-form food-item-details">
                    <div className="row">
                        <div className="col col-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="s-report-input">
                                <label className="form-label">From Date :</label>
                                <input type="date" className="form-control" name="start" onChange={(e) => { setDates({ ...dates, start: e.target.value }) }} max={dates.end} value={dates.start} />
                                <div className='error'>{error.start_err}</div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="s-report-input">
                                <label className="form-label">To Date :</label>
                                <input type="date" className="form-control" name="end" onChange={(e) => { setDates({ ...dates, end: e.target.value }) }} min={dates.start} value={dates.end} />
                                <div className='error'>{error.end_err}</div>
                            </div>
                        </div>
                        <div className='error'>{result}</div>
                        <div className="col col-12">
                            {status !== 'Download' ?
                                <div className="s-repost-btn s-report-generate text-center" >
                                    <button className="btn btn-success" disabled={disable} onClick={handleGenerate}>{status}</button>
                                </div>
                                : <div className="s-repost-btn s-report-generate text-center" >
                                    <button className="btn btn-success" onClick={downloadExcel}>{status}</button>
                                </div>}
                        </div>
                        {/* <div className="col col-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="s-repost-btn text-center">
                                <a className="btn btn-success">Open Report</a>
                            </div>
                        </div>
                        <div className="col col-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="s-repost-btn text-center">
                                <a href="#" className="btn btn-success">Send To Email</a>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SalesReports