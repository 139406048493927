import React, { useEffect, useState } from "react";
import firebase from "../firebase";
import { storage } from "../firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { toast, ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import uuid from "react-native-uuid";
import CropEasy from "./cropEasy";

const AddItem = () => {
  const [resto, setResto] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const [categories, setCategories] = useState([]);
  const [itemDetails, setItemDetails] = useState({
    item_name: "",
    item_description: "",
    item_price: "",
    item_available_in_stock: true,
    item_show_in_popular: false,
  });
  const [categoryId, setCategoryId] = useState("");
  const [disable, setDisable] = useState(false);
  const [imgErr, setImgErr] = useState("");
  const [modifierErr, setModifierErr] = useState({});
  const [groupErr, setGroupErr] = useState({});
  const [itemErr, setItemErr] = useState({});
  const [image, setImage] = useState("");
  const [progress, setProgress] = useState("");
  const [modifiers, setModifiers] = useState([
    { id: uuid.v4(), modifier_name: "", set_price: 0, is_enable: true },
  ]);
  const [modifierSuggestions, setModifierSuggestions] = useState([]);
  const [saveIntoSuggestions, setSaveIntoSuggestions] = useState(false);
  const [enableModifier, setEnableModifier] = useState(true);
  const [groupName, setGroupName] = useState("");
  const [required, setRequired] = useState(true);
  const [selectAs, setSelectAs] = useState("Single");
  const [groups, setGroups] = useState([]);
  const [id, setId] = useState("");
  const [status, setStatus] = useState("Done");
  const [photoURL, setPhotoURL] = useState("");
  const [openCrop, setOpenCrop] = useState(false);
  const [photoName, setPhotoName] = useState("");
  const [file, setFile] = useState(null);

  const handleItems = (e) => {
    setItemDetails({ ...itemDetails, [e.target.name]: e.target.value });
  };

  const handleImage = (e) => {
    let img = e.target.files[0];
    if (
      img.type === "image/jpeg" ||
      img.type === "image/jpg" ||
      img.type === "image/png"
    ) {
      setFile(img);
      setPhotoURL(URL.createObjectURL(img));
      setOpenCrop(true);
      // setImage(img);
    } else {
      setImgErr("Incorrect Image Format.");
    }
  };

  function blobToFile(theBlob, fileName) {
    return new File([theBlob], fileName, {
      lastModified: new Date().getTime(),
      type: theBlob.type,
    });
  }

  const croppedImage = (file, url) => {
    var myFile = blobToFile(file, file.name);
    console.log("file", myFile);
    setImgErr("");
    setProgress(0);
    console.log(myFile);
    const storageRef = ref(storage, `item_images/${Date.now()}`);
    const uploadTask = uploadBytesResumable(storageRef, myFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setImage(url);
          setProgress("");
        });
      }
    );
  };

  useEffect(() => {
    getCategoryList();
    getItemDetails();
    getModifiersList();
    getProfile();
  }, []);


  const getProfile = () => {
    firebase.firestore().collection('restaurants').doc(localStorage.getItem('CB_settings_ID')).get().then(doc => {
      setResto(doc.data());
      // setLoading(false);
    })
  }
  useEffect(() => {
    progress !== "" ? setDisable(true) : setDisable(false);
  }, [progress]);

  const getItemDetails = () => {
    if (location.state) {
      let item = location.state.item;
      setImage(item.item_image);
      setItemDetails({
        ...itemDetails,
        item_name: item.item_name,
        item_description: item.item_description,
        item_price: item.item_price,
      });
      setCategoryId(item.category_id);
    }
  };

  const getCategoryList = () => {
    firebase
      .firestore()
      .collection("categories")
      .where("is_enable", "==", true)
      .where("is_admin", "==", true)
      .get()
      .then((querySnapshot) => {
        let arr = [];
        querySnapshot.forEach((doc) => {
          arr.push({
            cat_id: doc.id,
            cat_name: doc.data().category_name,
            cat_image: doc.data().category_image,
          });
        });
        setCategories(arr.reverse());
      });
  };

  const getModifiersList = async () => {
    let arr = [];
    let res_arr = [];
    await firebase
      .firestore()
      .collection("modifiers")
      .where("is_enable", "==", true)
      .orderBy('added_on', "desc")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          arr.push({
            grp_name: doc.data().group_name,
            modifier: doc.data().modifiers.map((mod) => {
              return mod;
            }),
            added_on: Number(doc.data().added_on)
          });
        });
      });
    await firebase
      .firestore()
      .collection("restaurant_modifiers")
      .where("is_enable", "==", true)
      .where("restaurant_id", "==", localStorage.getItem('CB_settings_ID'))
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          res_arr.push({
            grp_name: doc.data().group_name,
            modifier: doc.data().modifiers.map((mod) => {
              return mod;
            }),
            added_on: Number(doc.data().added_on)
          });
        });
      });
    setModifierSuggestions(arr?.sort((a, b) => a.added_on - b.added_on).concat(res_arr?.sort((a, b) => a.added_on - b.added_on)));
  };
  const handleModifier = (e, id) => {
    const newDetails = modifiers.map((obj) => {
      if (obj.id === id) {
        if (e.target.name === "modifier_name") {
          return { ...obj, modifier_name: e.target.value };
        } else {
          return { ...obj, set_price: parseFloat(e.target.value) };
        }
      }
      return obj;
    });
    setModifiers(newDetails);
  };

  const handleModifierSuggestion = (elem) => {
    setGroupName(elem.grp_name);

    let arr = [];
    elem.modifier.map((mod) => {
      arr.push({
        id: uuid.v4(),
        modifier_name: mod,
        set_price: 0,
        is_enable: true,
      });
    });
    setModifiers(arr);
    setStatus("Suggestions");
    setSaveIntoSuggestions(false);
  };

  const validateModifier = () => {
    let isValid = true;
    let err = {};

    if (!modifiers[modifiers.length - 1].modifier_name) {
      isValid = false;
      err[`modifier_name_${modifiers.length - 1}_err`] =
        "Please enter modifier name";
    }
    // console.log(modifiers[modifiers.length - 1].set_price);
    if (parseFloat(modifiers[modifiers.length - 1].set_price) < 0) {
      isValid = false;
      err[`set_price_${modifiers.length - 1}_err`] = "Please enter valid price";
    }
    setModifierErr(err);
    return isValid;
  };

  const addModifier = () => {
    if (validateModifier()) {
      let temp = modifiers;
      temp[temp.length - 1].set_price = isNaN(
        modifiers[modifiers.length - 1].set_price
      )
        ? 0
        : modifiers[modifiers.length - 1].set_price;

      setModifiers([
        ...temp,
        { id: uuid.v4(), modifier_name: "", set_price: 0, is_enable: true },
      ]);
    }
  };

  const handleDeleteModifier = (id) => {
    setModifiers(modifiers.filter((mod) => mod.id !== id));
  };

  const validateGroup = () => {
    let isValid = true;
    let err = {};
    let err2 = {};

    if (!groupName) {
      isValid = false;
      err["group_name_err"] = "Please enter group name";
    }

    // if (groups.length > 0) {
    //     if (groups.every(mod => mod.group_name.toLowerCase() === groupName.toLowerCase())) {
    //         isValid = false;
    //         err['group_name_err'] = "Group name already exists"
    //     }
    // }
    if (modifiers.length === 1) {
      if (!modifiers[0].modifier_name) {
        isValid = false;
        err["modifier_err"] = "Please add atleast one modiifer";
      }
    } else {
      modifiers.map((mod, index) => {
        if (!mod["modifier_name"]) {
          isValid = false;
          err2[`modifier_name_${index}_err`] = "Please enter modifier name";
        }
        // else if (isNaN(mod["set_price"])) {
        //   isValid = false;
        //   err2[`set_price_${index}_err`] = "Please enter price";
        // }
        else if (parseFloat(mod["set_price"]) < 0) {
          isValid = false;
          err2[`set_price_${index}_err`] = "Please enter valid price";
        }
      });
    }
    setGroupErr(err);
    setModifierErr(err2);
    return isValid;
  };

  const addGroup = () => {
    if (validateGroup()) {
      let modifier_arr = [];
      modifiers.map((elem) => {
        modifier_arr.push({
          modifier_name: elem.modifier_name,
          set_price: isNaN(elem.set_price) ? 0 : elem.set_price,
          is_enable: elem.is_enable,
        });
      });
      console.log(modifier_arr);
      setGroupErr({});
      setGroups([
        ...groups,
        {
          id: uuid.v4(),
          enable_modifier: enableModifier,
          group_name: groupName,
          modifiers: modifier_arr,
          required: required,
          select_as: selectAs,
          save_into_suggestion: saveIntoSuggestions
        },
      ]);
      setModifiers([{ modifier_name: "", set_price: 0, is_enable: true }]);
      setGroupName("");
      setEnableModifier(true);
      setRequired(true);
      setSelectAs("Single");
      setSaveIntoSuggestions(false);
      setStatus("Done")
    } else {
      setModifiers(
        modifiers.map((elem) => {
          return {
            modifier_name: elem.modifier_name,
            set_price: isNaN(elem.set_price) ? 0 : elem.set_price,
            is_enable: elem.is_enable,
          };
        })
      );
    }
  };

  const validateItems = () => {
    let isValid = true;
    let err = {};
    let input = itemDetails;

    // if (!image) {
    //     isValid = false;
    //     err['item_image_err'] = "Please select item image"
    // }
    if (!input["item_name"]) {
      isValid = false;
      err["item_name_err"] = "Please enter item name";
    }

    // if (!input["item_description"]) {
    //   isValid = false;
    //   err["item_description_err"] = "Please enter item description";
    // }

    if (
      !input["item_price"] ||
      isNaN(input["item_price"]) ||
      parseInt(input["item_price"]) < 0
    ) {
      isValid = false;
      if (parseInt(input["item_price"]) < 0 || isNaN(input["item_price"]))
        err["item_price_err"] = "Please enter valid price";
      else err["item_price_err"] = "Please enter item price";
    }

    if (!categoryId) {
      isValid = false;
      err["category_err"] = "Please select one catgeory";
    }
    setItemErr(err);
    return isValid;
  };

  const handleEdit = (element) => {
    setId(element.id);
    setStatus("Update");
    setGroupName(element.group_name);
    setEnableModifier(element.enable_modifier);
    setRequired(element.required);
    setSelectAs(element.select_as);
    setSaveIntoSuggestions(element.save_into_suggestion)
    let modifer_arr = [];
    element.modifiers.map((elem) => {
      modifer_arr.push({ id: uuid.v4(), ...elem });
    });
    setModifiers(modifer_arr);
  };

  const handleCancel = () => {
    setId("");
    setStatus("Done");
    setGroupName("");
    setEnableModifier(true);
    setRequired(true);
    setSelectAs("Single");
    setModifiers([{ modifier_name: "", set_price: 0, is_enable: true }]);
    setModifierErr({});
    setGroupErr({});
    setSaveIntoSuggestions(false);
  };

  const handleUpdate = () => {
    if (validateGroup()) {
      setGroupErr({});
      let modifier_arr = [];
      modifiers.map((elem) => {
        modifier_arr.push({
          modifier_name: elem.modifier_name,
          set_price: isNaN(elem.set_price) ? 0 : elem.set_price,
          is_enable: elem.is_enable,
        });
      });
      const finalGroup = groups.map((obj) => {
        if (obj.id === id) {
          return {
            ...obj,
            enable_modifier: enableModifier,
            group_name: groupName,
            modifiers: modifier_arr,
            required: required,
            select_as: selectAs,
            save_into_suggestion: saveIntoSuggestions,
          };
        }
        return obj;
      });
      setGroups(finalGroup);
      setId("");
      setStatus("Done");
      setGroupName("");
      setEnableModifier(true);
      setRequired(true);
      setSelectAs("Single");
      setSaveIntoSuggestions(false);
      setModifiers([{ modifier_name: "", set_price: 0, is_enable: true }]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(groups);
    if (validateItems()) {
      setDisable(true);
      await firebase
        .firestore()
        .collection("items")
        .add({
          added_on: Date.now().toString(),
          category_id: categoryId,
          item_available_in_stock: itemDetails.item_available_in_stock,
          item_description: itemDetails.item_description || "",
          item_image: image ? image : null,
          item_name: itemDetails.item_name,
          item_price: itemDetails.item_price,
          item_quantity: 0,
          item_show_in_popular: itemDetails.item_show_in_popular,
          item_type: 0,
          restaurant_id: localStorage.getItem("CB_settings_ID"),
        })
        .then(async (docRef) => {
          console.log(docRef.id);
          const ref = await firebase
            .firestore()
            .collection("restaurants")
            .doc(localStorage.getItem("CB_settings_ID"))
            .collection("arrange_menu_items")
            .doc(localStorage.getItem("CB_settings_ID"));
          const doc = await ref.get();
          if (doc.exists) {
            ref
              .update({
                [categoryId]: firebase.firestore.FieldValue.arrayUnion(
                  docRef.id
                ),
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            ref
              .set({
                [categoryId]: firebase.firestore.FieldValue.arrayUnion(
                  docRef.id
                ),
              })
              .catch((e) => {
                console.log(e);
              });
          }

          // if (groups.length > 0) {
          let grp_arr = [];
          await Promise.all(groups.map((elem) => {
            grp_arr.push({
              enable_modifier: elem.enable_modifier,
              group_name: elem.group_name,
              modifiers: elem.modifiers,
              required: elem.required,
              select_as: elem.select_as,
            });
          }));
          let save_group_arr = groups.filter(e => e.save_into_suggestion === true);
          await Promise.all(save_group_arr?.map(async elem => {
            await firebase.firestore().collection('restaurant_modifiers').add({
              group_name: elem.group_name,
              modifiers: elem.modifiers?.map(el => el?.modifier_name),
              added_on: Date.now().toString(),
              is_enable: elem.enable_modifier,
              restaurant_id: localStorage.getItem('CB_settings_ID')
            }).then(async (docRef) => {
              await firebase.firestore().collection('restaurant_modifiers').doc(docRef.id).update({ modifier_id: docRef.id });
            })
          }));
          await firebase
            .firestore()
            .collection("items")
            .doc(docRef.id)
            .collection("item_modifier")
            .add({
              added_on: Date.now().toString(),
              item_id: docRef.id,
              item_name: itemDetails.item_name,
              modifier_groups: grp_arr,
            })
            .then(async (doc) => {
              await firebase
                .firestore()
                .collection("items")
                .doc(docRef.id)
                .collection("item_modifier")
                .doc(doc.id)
                .update({
                  id: doc.id,
                });
              await firebase.firestore().collection("items").doc(docRef.id).update({
                item_id: docRef.id,
                item_modifier_id: doc.id,
              });
              setDisable(false);
              toast.success("Item Added Successfully");
              // setTimeout(() => {
              //   navigate("/items");
              // }, [2000]);
              setItemDetails({
                item_name: "",
                item_description: "",
                item_price: "",
                item_available_in_stock: true,
                item_show_in_popular: false,
              });
              setCategoryId("");
              setDisable(false);
              setImgErr("");
              setModifierErr({});
              setGroupErr({});
              setItemErr({});
              setImage("");
              setProgress("");
              setModifiers([
                { id: uuid.v4(), modifier_name: "", set_price: 0, is_enable: true },
              ]);
              setEnableModifier(true);
              setGroupName("");
              setRequired(true);
              setSelectAs("Single");
              setGroups([]);
              setId("");
              setStatus("Done");
              setPhotoURL("");
              setOpenCrop(false);
              setPhotoName("");
              setFile(null);
              getModifiersList();
            });
          // } else {
          //     firebase.firestore().collection('items').doc(docRef.id).update({
          //         item_id: docRef.id,
          //     })
          //     setDisable(false);
          //     toast.success("Item Added Successfully");
          //     setTimeout(() => {
          //         navigate('/items');
          //     }, [2000])
          // }
        });
    }
  };
  const handleClose = () => {
    setOpenCrop(false);
  };

  return (
    <>
      <ToastContainer />
      <CropEasy
        photoURL={photoURL}
        file={file}
        activeModal={openCrop}
        setActiveModal={handleClose}
        croppedImage={croppedImage}
      />
      <div
        className="food-add-item customers-product-order"
        tabIndex="-1"
        style={{ overflow: "hidden", outline: "none ", overflowY: "auto" }}
      >
        <div
          className="scroll-content"
          style={{ transform: "translate3d(0px, 0px, 0px)" }}
        >
          {/* <div className="customers-product-order-search">
                    <h2 className="food-add-item-title">Add Item</h2>
                    <div className="customers-product-top-btn food-item-top-btn">
                        <button disabled={disable} className="btn btn-success" onClick={handleSubmit}>{disable ? 'Processing' : 'Save Item'}</button>
                    </div>
                </div> */}

          <div className="food-items-form">
            <div className="row">
              <div className="col col-12 col-md-12 col-lg-6 col-xl-6">
                <div className="food-item-details">
                  <div className="food-details-heading">
                    <h4 className="food-details-title">Items Details</h4>
                  </div>
                  <div className="food-details-form">
                    <div className="food-upload-img">
                      {!image ? (
                        <div className="food-upload-browse">
                          {!progress ? (
                            <label className="fomr-label">
                              Upload Item Image
                            </label>
                          ) : (
                            <label className="fomr-label">
                              Uploading .... {progress}%
                            </label>
                          )}
                          <input type="file" onChange={handleImage} />
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingRight: "0px",
                            position: "relative",
                          }}
                        >
                          <img
                            src={image}
                            alt="item-img"
                            style={{
                              width: "240px",
                              height: "200px",
                              objectFit: "contain",
                              borderRadius: "10px",
                            }}
                          />
                          <div
                            style={{
                              position: "absolute",
                              right: "-20px",
                              top: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => setImage("")}
                          >
                            <span className="cancel-icon">
                              <strong>X</strong>
                            </span>
                          </div>
                        </div>
                      )}
                      <div className="error" style={{ textAlign: "right" }}>
                        {imgErr}
                      </div>
                      <div className="error" style={{ textAlign: "right" }}>
                        {itemErr.item_image_err}
                      </div>
                      <div style={{ textAlign: "right", color: "orange" }}>
                        Recommanded size 1920*1080
                      </div>
                    </div>
                    <div className="food-details-input">
                      <label className="form-label">Item Name</label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Item Name"
                          name="item_name"
                          onChange={handleItems}
                          value={itemDetails.item_name}
                        />
                        <div className="error">{itemErr.item_name_err}</div>
                      </div>
                    </div>
                    <div className="food-details-input food-details-discription">
                      <label className="form-label">Description</label>
                      <div>
                        <textarea
                          className="form-control"
                          cols="30"
                          rows="5"
                          placeholder="Description"
                          name="item_description"
                          onChange={handleItems}
                          value={itemDetails.item_description}
                        ></textarea>
                        <div className="error">
                          {itemErr.item_description_err}
                        </div>
                      </div>
                    </div>
                    <div className="food-details-input">
                      <label className="form-label">Price</label>
                      <div>
                        <input
                          type="number"
                          className="form-control"
                          placeholder={resto?.currency_symbol ?? '$'}
                          name="item_price"
                          onChange={handleItems}
                          value={itemDetails.item_price}
                          min="0"
                          onWheel={(e) => e.target.blur()}
                        />
                        <div className="error">{itemErr.item_price_err}</div>
                      </div>
                    </div>
                    <div className="food-details-input mb-0">
                      <label className="form-label">Category</label>
                      <div>
                        <select
                          className="form-check"
                          style={{
                            width: "240px",
                            height: "50px",
                            borderRadius: "8px",
                          }}
                          name="category_id"
                          onChange={(e) => setCategoryId(e.target.value)}
                          value={categoryId}
                        >
                          <option style={{ display: "none" }} value="">
                            Select Category
                          </option>
                          {categories.map((elem) => (
                            <option value={elem.cat_id} key={elem.cat_id}>
                              {elem.cat_name}
                            </option>
                          ))}
                        </select>
                        <div className="error">{itemErr.category_err}</div>
                      </div>
                    </div>
                    <div className="category-available">
                      <div className="form-check">
                        <label
                          className="form-check-label"
                          htmlFor="stockCheck1"
                        >
                          Available In Stock
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={itemDetails.item_available_in_stock}
                          id="stockCheck1"
                          onChange={() =>
                            setItemDetails({
                              ...itemDetails,
                              item_available_in_stock:
                                !itemDetails.item_available_in_stock,
                            })
                          }
                        />
                      </div>
                      <div className="form-check">
                        <label
                          className="form-check-label"
                          htmlFor="stockCheck2"
                        >
                          Show In Popular Item
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={itemDetails.item_show_in_popular}
                          id="stockCheck2"
                          onChange={() =>
                            setItemDetails({
                              ...itemDetails,
                              item_show_in_popular:
                                !itemDetails.item_show_in_popular,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="food-category food-item-details">
                                <div className="food-category-heading food-details-heading">
                                    <h4 className="food-details-title">item Category</h4>
                                </div>
                                <div className="food-category-form food-details-form">
                                    <div className="form-check food-category-chekbox">
                                        
                                    </div>
                                    <div className='error'>{itemErr.category_err}</div>
                                </div>
                            </div> */}
              </div>
              <div className="col col-12 col-md-12 col-lg-6 col-xl-6">
                <div className="food-other-item food-item-details">
                  <div className="food-details-heading">
                    <h4 className="food-details-title">Modifier Group</h4>
                  </div>
                  <div className="suggestions">
                    <div className="row">
                      <div className="col-3">
                        <label>Suggestions:</label>
                      </div>
                      <div
                        className="col-9 modifier-sugg"
                        style={{ textAlign: "left" }}
                      >
                        {modifierSuggestions.map((elem) => (
                          <span onClick={() => handleModifierSuggestion(elem)}>
                            {elem.grp_name}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="food-other-item-form food-details-form">
                    <div className="modifer-input food-details-input">
                      <label className="form-label">Group Name</label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          name="group_name"
                          value={groupName}
                          onChange={(e) => setGroupName(e.target.value)}
                        />
                        <div className="error">{groupErr.group_name_err}</div>
                      </div>
                    </div>
                    {modifiers.map((elem, index) => (
                      <div
                        className="row"
                        key={index}
                        style={{ marginBottom: "10px" }}
                      >
                        <div className="col-sm-6 modifer-input modifier-details-input">
                          <label className="form-label">Modifier Name</label>
                          <input
                            type="text"
                            className="form-control"
                            value={elem.modifier_name}
                            name="modifier_name"
                            id="modifier_name"
                            onChange={(e) => handleModifier(e, elem.id)}
                          />
                          <div className="error">
                            {modifierErr[`modifier_name_${index}_err`]}
                          </div>
                        </div>
                        <div className="col-sm-5 modifer-input modifier-details-input mb-0">
                          <label className="form-label">Set Price</label>
                          <input
                            type="number"
                            className="form-control"
                            value={elem.set_price}
                            name="set_price"
                            id="set_price"
                            onChange={(e) => handleModifier(e, elem.id)}
                            onWheel={(e) => e.target.blur()}
                            min="0"
                          />
                          <div className="error">
                            {modifierErr[`set_price_${index}_err`]}
                          </div>
                        </div>
                        {modifiers.length > 1 ? (
                          <div
                            className="col-sm-1 d-flex align-items-center justify-content-center"
                            style={{ paddingTop: "10px" }}
                          >
                            <i
                              className="fa fa-trash-alt"
                              onClick={() => handleDeleteModifier(elem.id)}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                    <div className="error">{groupErr.modifier_err}</div>
                    <div className="modifier-add-item">
                      <span onClick={addModifier} style={{ cursor: "pointer" }}>
                        + Add{" "}
                      </span>
                    </div>
                    <div className="modifier-item-select">
                      <span className="modifier-select-title">Select as</span>
                      <div className="modifier-select-btn">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="select_as"
                            id="modifierRadio1"
                            checked={selectAs === "Single"}
                            onChange={(e) => setSelectAs(e.target.value)}
                            value="Single"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="modifierRadio1"
                          >
                            Single
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="select_as"
                            id="modifierRadio2"
                            checked={selectAs === "Multiple"}
                            onChange={(e) => setSelectAs(e.target.value)}
                            value="Multiple"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="modifierRadio2"
                          >
                            Multiple
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="modifier-switch-btn">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input border-0"
                          type="checkbox"
                          name="enable_modifier"
                          id="modifierSwitch"
                          checked={enableModifier}
                          onChange={() => setEnableModifier(!enableModifier)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="modifierSwitch"
                        >
                          Enable Modifier
                        </label>
                      </div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input border-0"
                          type="checkbox"
                          name="required"
                          id="modifierSwitch1"
                          checked={required}
                          onChange={() => setRequired(!required)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="modifierSwitch1"
                        >
                          Required
                        </label>
                      </div>
                      <div className="form-check form-switch mb-0">
                        <input
                          className="form-check-input border-0"
                          type="checkbox"
                          name="required"
                          id="modifierSwitch2"
                          checked={saveIntoSuggestions}
                          onChange={() => setSaveIntoSuggestions(!saveIntoSuggestions)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="modifierSwitch2"
                        >
                          Save modifier group for later
                        </label>
                      </div>
                    </div>
                    <div className="error">{itemErr.group_err}</div>
                    {status === "Done" ? (
                      <div className="modifier-btn">
                        <button
                          className="btn btn-success"
                          style={{ textTransform: "unset" }}
                          onClick={addGroup}
                        >
                          Save Modifier
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                    {status === "Update" ? (
                      <div className="modifier-btn">
                        <button
                          className="btn btn-success"
                          style={{
                            marginRight: "10px",
                            textTransform: "unset",
                          }}
                          onClick={handleUpdate}
                        >
                          Update Modifier
                        </button>
                        <button
                          className="btn btn-cancel"
                          style={{ textTransform: "unset" }}
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                    {status === "Suggestions" ? (
                      <div className="modifier-btn">
                        <button
                          className="btn btn-success"
                          onClick={addGroup}
                          style={{
                            marginRight: "10px",
                            textTransform: "unset",
                          }}
                        >
                          Save Modifier
                        </button>
                        <button
                          className="btn btn-cancel"
                          style={{ textTransform: "unset" }}
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {groups.length > 0 ? (
                    <table className="table" style={{ background: "#F6F6F6" }}>
                      <thead>
                        <tr>
                          <th>
                            <b>Sr. No.</b>
                          </th>
                          <th>
                            <b>Group Name</b>
                          </th>
                          <th>
                            <b>Action</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {groups.map((elem, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{elem.group_name}</td>
                            <td
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <i
                                className="fa fa-edit"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleEdit(elem)}
                              />
                              <i
                                className="fa fa-trash"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  setGroups(
                                    groups.filter((grp) => grp.id !== elem.id)
                                  )
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className="customers-product-top-btn food-item-top-btn"
                  style={{ float: "right", width: "100%" }}
                >
                  <button
                    disabled={disable}
                    className="btn btn-success"
                    style={{
                      width: "100%",
                      padding: "0.8rem",
                      fontWeight: "500",
                      fontSize: "20px",
                    }}
                    onClick={handleSubmit}
                  >
                    {disable ? "Processing" : "Save Item"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddItem;
