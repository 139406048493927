import firebase from 'firebase/compat/app';
import firestore from 'firebase/compat/firestore';
import 'firebase/compat/storage'

const settings = { timestampsInSnapshots: true };
let config;
// if (window.location.host === "partner.clubgrub.co") {
config = {
  "type": "service_account",
  "projectId": "clubgrub-e04f0",
  "private_key_id": "28a4e719ff6d0241e2caf77c9e7f1ad367652394",
  "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDXCtrjS7dxjKNV\nK/8o3dhXhFqqzHoum0EMsM8lwjUrQIVDMkP4rSs819aAcMzdIxw6oMn5EXvLunzs\nNslorvSczL8ZClE2aG/B+kL5kZcmN3twQL8E9OhNlw71hiC5nKY+qrNh+n/h1pIQ\nIifzatgGr9Q93Rbr41ElE6jDOnaF8obYUoPtUv6bRkLSqVJ+F5czMi9rdDY2mdI6\nhzX6H24aB+6+jJdmwVAwNRhsmFEKZwkZEVkcCQMrCiid8YMGH74k5ewtgPR/kWmh\nJMAB07RvvVlr3kR3LSIgSlM6cQB0EVJAn9KqTQdXklsz+hbkMGb/CaaB2w/44DC6\nXLU66PWdAgMBAAECggEAF3z5tZ6n6yuCYk42Rkoc/vTXoRTz5N1TuJrOCtYbT/Mc\nhd9v/zHi/s1Qgkz6g3j59LQVUN2GWRVbNG3eMqSm1phNPeNGRZwYq5ELTw6GT51Q\ndU1iepx0N0K1q+m6RKvbQ2MKdDw+oEtQlESMJTwEBo70BCHUXBrt2mU6/MAJAXjt\n21Kwp4l8LGFSSOwIuX4eDauaO1ukoHhX8jWmferFiVt61Vwpjyba4S40CO+BO5yB\nPCALjveo3q2F/FImap1Aj75bJYLpqkbl18LlhOVD9fdvJ/Bg10y7z1jATg6V9zoa\nIGi/owsThq4G3lwNuHBvGawH0HrNOXMYmL5g6XRRvQKBgQDuKkdTA7uJoijwmtaP\njdcb1tY21UUtAo160f1RMJ8UeVeNOAeIMTMe8qNp/ld170R6DakB0cvmleyRmfTD\n4NU1LawRBna3NDSNuCTk/+fb0P+KtLj3aCF0BjfUIEPvKmMuqTncipn4meKkQlNF\nis7+LKImNE9QjBmlZWDRuTQNowKBgQDnJU3XXe8b+hkjEwk9jJ+H8eH6B85DXGzW\ncNylnDdzK2Accy8vQMs84sn2v9skKicYHJZg7voWKz6WPwzZZFEJmohSrPHUxxGo\neNVrURJIui5V98LqDkk2Jsiclt82M5CNAEIpV9tafqQhvafdKz3IWgjwiw8pZ3KJ\nVcJ6dEyjvwKBgQCeekGVqsEq1124pL+rLG3711wZhpaOWcl89ahDU0A3pnqsvKC+\nbkafDEPS7uyRzFHbiaVxUYIFgLO3OyPVB5G33hlGBvAN/k4OE39xZhuJeKviR1lh\nP8gq5UxW+dVamvD+hpq69vt3oiZeuMeiWbhdB6QqGdrBNUiHd0sSotNRUwKBgQDA\nJfKfpiavSyi6T4jO8adsi1cfUn3U4XBYk1nZEhPGWbh77gS7c1GAO8dIqBSz65a0\nJI+d5QtggnJ009UKoqn7IctRbxNZnmK/ejNwEuTwMFV0gZI2n8mcioEaKxFDLXps\nvFpDpBVlFQG8SrzQzrF9a9sim3bvJF/CathQk31iYQKBgB7ezo4nA45Ra5reteTe\nz050lBtZNMW8H13AjJ8EpmO/598QrbNDcLF8yZaDkDIUd3FrZPy8RS7WGwtmXVpP\nZzbNKcvyb/7wG5ebEdYcLLLLskzOJiST8DZYc+E5DwoWiNqZ7Xc2170pw3kOzWaY\nFXqR4w6yzULAlNuoLkxkxv8S\n-----END PRIVATE KEY-----\n",
  "client_email": "firebase-adminsdk-zfc3x@clubgrub-e04f0.iam.gserviceaccount.com",
  "client_id": "105311042456052778920",
  "auth_uri": "https://accounts.google.com/o/oauth2/auth",
  "token_uri": "https://oauth2.googleapis.com/token",
  "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
  "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-zfc3x%40clubgrub-e04f0.iam.gserviceaccount.com",
  "storageBucket": "clubgrub-e04f0.appspot.com"
}
// }
// else {

//   config = {
//     "type": "service_account",
//     "projectId": "clubgrub-development",
//     "private_key_id": "1f369083413691b455319d2863738a50e84746c3",
//     "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQC/E70zzzC1jK5e\n2sDuUtsiMucEHA929FVGRKEUSFiNEUJGCP1dnEMbq6DmZe0wLaDyRTlpYYILxeF5\nht/pUoLZMBB6SvVWGPugt9YA5fo+OUyq7OiiU5aylpuK169ERS6ZU9pDQNJSorix\naQ9N+HSB6yokNO1f6hcNFgfk3X1idrTc8l8JlXqdYGsUz10U9OUNLWYvZHBxDB7+\n8pmmFkbsd/+iXm1SZDx0QXWu0RTmFERcQ86C/rcL2HBgsZBRq5RcxzV+3oii1XCM\n69rELjVbG8E4Bh92vzsQb2nBIx8SOovVxirUAbwDoxvdwN9viZwaT2Y14l0J+TXV\n6KAWfX+tAgMBAAECggEAAo2c/HEedI2/w8KI2Ptb3CJz4qcnv3glVAm2WEME8Cpx\naBsYeR4AXJd7JBqk3o6+57je+M/V0qen+68FQUyecvGPhfpBb0i4R3qvB3NjhCAP\nFnWIdXyb/8zeYzvzojnq6NjKHGWz6Ka7HOGw9w7fKtdjF+NYrdIXOzWMwXzn9kJm\nyZHb78feVbgDWuqiSS3w9gGGp352HsyhPMEP8qTulN5OpEAj9P7e1uNGEPV5MNho\nv0F9D8ZOVy2CDUpS7BqmQA6SHZ5bxLiwtE1FDcSEFRp22XkLFTVL8Hbt0mmcRL+i\nG3194JXu8RQLGM2NWJAZBBLRh/PP4ljAHgeq5v1bywKBgQD3Keyf/XH34reaENgb\nEXKRafJE40Vu1Ai0ilpYbgsekCV7xOrDYXn7brrRUWz2rEDpXQ2hnXwTH6fwXE6N\nhOKZVNTRBLFZkHkNA1oY6n6MWfbHE4c8+UHS39olEpB/+EKkW+gp+WyTlj5OC7/c\nqD5Z0gR4S/rxcRcnjok3S45ZbwKBgQDF6ICOMlXZaUiSOWvI90KoSgWqoOuDBX7U\nwh38BXBvrFa7GsUUOcRdOhz8gvHPwbEj7JRXLY8qv9WEQ7lF+4SBfs7p/SZ2g6XY\n3znkZ9D61WLnzeevji2aCYeADqPH8tlwBqsQmim3egYkWeQoPUhohfF1lXrmm9uV\no4a2hJtSowKBgDS+FIl7ivNrxOQyjfsQuRMAubsriGiDBKyKDYdrTCIkfmsv9Szm\nQyJ9WY5LQuZMkbl6qur+8TgffDXVbMnUPoqbyKIlzzaelsZJHdPNTl3i4L9Qkn5X\nbk1yiVl54a39KzNpjVxPpnTEDd2XskSC/nMqVtvY0t7s1BUT3IIZNHIvAoGABdJ5\nNbW1YNgAEo0t5pOfmmPTFtn1E72tpYSwbSGb1S00v/xhn96zMCrtf2A6zOnd1Di5\nhHcKpy9NkL6NTMnBdC3O2INiBkqJLoAiq92CAF8WscsU7c04+jcdjV7g4pj6XJlV\nnv9qCsPEOn2KgMSMxMZr/BGRtAcRxq0w4V21VBECgYEAx+nPlvVUTzWNBb0804DP\nVIvT6P55Ij6a0Zc1UiV0YuReCH5vb1iyzuPipFM9ABhZqW5juY3Zc6KQFurZKjw+\nu+7e5B3JK7dwI6n9HY42BJWWe0vb5CL6X3qCesHUTXwKi2uQa5tpzCsMrqWDjJnk\newvzTNFkoJPVVzyzDLUc8IM=\n-----END PRIVATE KEY-----\n",
//     "client_email": "firebase-adminsdk-scm7g@clubgrub-development.iam.gserviceaccount.com",
//     "client_id": "100528296209627932281",
//     "auth_uri": "https://accounts.google.com/o/oauth2/auth",
//     "token_uri": "https://oauth2.googleapis.com/token",
//     "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
//     "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-scm7g%40clubgrub-development.iam.gserviceaccount.com",
//     "apiKey": "AIzaSyAFzcIzjw8ZdLz7fIbxsuJvw5qODKiyrJw",
//     "authDomain": "clubgrub-development.firebaseapp.com",
//     "storageBucket": "clubgrub-development.appspot.com",
//     "messagingSenderId": "382896480693",
//     "appId": "1:382896480693:web:d820b703c4ddb4783579fc"
//   }

// }

firebase.initializeApp(config);
const storage = firebase.storage()


firebase.firestore().settings(settings);

export {
  storage, firebase as default
}
