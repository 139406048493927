import React, { useEffect } from 'react'
import Items from './Items'
import Category from './Category'
import Catalogue from './Catalogue'
import SalesReports from './SalesReports'
import Header from './partial/Header'
import Slidebar from './partial/Slidebar'
import { useNavigate } from "react-router-dom";
import AddItem from './AddItem'
import EditItem from './EditItem'
import Profile from './Profile'
import CategoryItems from './CategoryItems'
import Dashboard from './Dashboard'
import Modifiers from './Modifiers'
import ManageModifier from './ManageModifier'

const DefaultLayout = ({ title }) => {


  let history = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('CB_settings_ID') == null)
      history('/');
  }, []);



  return (
    <>
      <section className="customers-section">
        <div className="customers-content">

          <Slidebar />
          <div className="customers-product">
            <Header title={title} />

            {title === "Add Item" && <AddItem />}
            {title === "Edit Item" && <EditItem />}
            {title === "Items" && <Items />}
            {title === "Modifiers" && <Modifiers />}
            {title === "Manage Modifier" && <ManageModifier />}
            {title === "Categories" && <Category />}
            {title === "Category Items" && <CategoryItems />}
            {title === "Catalog" && <Catalogue />}
            {title === "Sales Reports" && <SalesReports />}
            {title === "Profile" && <Profile />}
            {title === "Dashboard" && <Dashboard />}
          </div>
        </div>
      </section>
    </>
  )
}

export default DefaultLayout
