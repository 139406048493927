import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from '../firebase';

const Category = () => {
    const nav = useNavigate();
    const [categories, setCategories] = useState([]);
    const [items, setItems] = useState([]);
    const [displayCategories, setDisplayCategories] = useState([]);
    const rows = [5, 10, 20, 30];;
    const [categoriesPerPage, setCategoriesPerPage] = useState(rows[0]);
    const [currentPage, setCurrentPage] = useState(1);
    const [status, setStatus] = useState(true);

    useEffect(() => {
        getCategoryList();
        getItemList();
    }, [status])

    const getCategoryList = () => {
        firebase.firestore().collection('categories').where('is_enable', '==', true)
            .get().then(querySnapshot => {
                let arr = [];
                let index = querySnapshot.docs.length + 1;
                querySnapshot.forEach(doc => {
                    index -= 1;
                    arr.push({ cat_id: doc.id, cat_name: doc.data().category_name, cat_image: doc.data().category_image, index })
                });
                setCategories(arr.reverse());
            })
    }

    const getItemList = () => {
        firebase.firestore().collection('items').where('restaurant_id', '==', localStorage.getItem('CB_settings_ID')).get().then((querySnapshot) => {
            let arr = [];
            querySnapshot.forEach((doc) => {
                arr.push({ category_id: doc.data().category_id, item_available_in_stock: doc.data().item_available_in_stock })
            })
            setItems(arr);
        })
    }
    useEffect(() => {
        setDisplayCategories(categories)
    }, [categories]);

    const indexOfLastCategory = currentPage * categoriesPerPage;
    const indexOfFirstCategory = indexOfLastCategory - categoriesPerPage;
    //const currentCategories = displayCategories.slice(indexOfFirstCategory, indexOfLastCategory);
    const currentCategories = displayCategories;
    const lastPageNumber = Math.ceil(displayCategories.length / categoriesPerPage);

    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(displayCategories.length / categoriesPerPage); i++) {
        pageNumbers.push(i);
    }

    const paginate = (num) => {
        //setCurrentPage(num);
    }

    const handleSearch = (e) => {
        if (e.target.value) {
            let search = e.target.value;
            let arr = categories.filter(
                (elem) =>
                    elem.cat_name.toLowerCase().includes(search.toLowerCase())
            );
            setDisplayCategories(arr);
        } else {
            setDisplayCategories(categories)
        }
    }

    const handleItems = (id) => {
        const catItems = items.filter((it) => {
            //console.log(it);
            if (it.category_id === id && it.item_available_in_stock === status) {
                return it
            }
        })
        return catItems.length
    }

    const add3Dots = (string, limit) => {
        var dots = "...";
        if (string.length > limit) {
            string = string.substring(0, limit) + dots;
        }
        return string;
    }
    return (
        <>
            <div className="customers-product-order" style={{ overflow: 'auto' }}>
                <div className="customers-product-order-search">
                    <div className="customers-product-order-input">
                        <input type="text" className="form-control" placeholder="search category" onChange={handleSearch} />
                    </div>
                    <div className="customers-product-top-btn">
                        <div className="customers-product-filter">
                            <select className="form-select" value={status} onChange={() => setStatus(!status)}>
                                <option value="true">Active</option>
                                <option value="false">Non Active</option>
                            </select>
                        </div>
                        {/* <a href="#" className="btn btn-success">Add New</a> */}
                    </div>
                </div>
                <div className="customers-product-order-list">
                    <div className="table-responsive">
                        <table className="table mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <div className="form-check product-order-checkbox product-order-checkbox">
                                            <label className="form-check-label" htmlFor="orderCheck1">
                                                Category Image
                                            </label>
                                        </div>
                                    </th>
                                    <th scope="col">Category Name</th>
                                    <th scope="col">Category Item</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentCategories.map((elem, index) =>
                                    <tr key={index} style={{ cursor: 'pointer' }} onClick={() => { if ( items.filter(it => it.category_id === elem.cat_id && it.item_available_in_stock === status) .length > 0) { nav(`/category-item/${elem.cat_id}/${status}`) } }}>
                                        <td>
                                            <img src={elem.cat_image} alt="" className="img-fluid" style={{ width: "60px", height: "60px", objectFit: "cover" }} />
                                        </td>
                                        <td>{elem.cat_name}</td>
                                        <td><span className='customers-sidebar-link' style={{color:'#66AD27',width:'unset'}}>{handleItems(elem.cat_id)} Items</span></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {currentCategories.length > 0 ? <div className="product-order-pages">
                    <h6 className="product-order-page-title">Showing {currentCategories[currentCategories.length - 1].index} of {displayCategories.length} Data</h6>
                    <nav aria-label="Page navigation example">
                        <ul className="pagination">
                            <li className="page-item">
                                <button className="page-link-btn page-link-prev" disabled={currentPage === 1} onClick={() => paginate(currentPage - 1)}>Prev</button>
                            </li>
                            <li className="page-item ">
                                <a className="page-link page-link-number active">{currentPage}</a>
                            </li>
                            <li className="page-item">
                                <button className="page-link-btn page-link-next" disabled={currentPage === lastPageNumber} onClick={() => paginate(currentPage + 1)}>Next</button>
                            </li>
                        </ul>
                    </nav>
                </div> : ''}
            </div>

        </>

    )
}

export default Category