import React from "react";

import {
  BrowserRouter as Router,
  Routes, Route
} from "react-router-dom";

import logo from './logo.svg';
import './App.css';
import Login from './components/restaurant/Login'
import DefaultLayout from './components/restaurant/DefaultLayout'
import ForgotPassword from "./components/restaurant/ForgotPassword";

const NotFound = () => {
  return (
    <div>NotFound</div>
  )
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Login />} />
        <Route path="/forgot-password" exact element={<ForgotPassword />} />
        <Route path="/dashboard" exact element={<DefaultLayout title='Dashboard' />} />
        <Route path="/add-item" exact element={<DefaultLayout title='Add Item' />} />
        <Route path="/edit-item" exact element={<DefaultLayout title='Edit Item' />} />
        <Route path="/items" exact element={<DefaultLayout title='Items' />} />
        <Route path="/modifiers" exact element={<DefaultLayout title='Modifiers' />} />
        <Route path="/manage-modifier" exact element={<DefaultLayout title='Manage Modifier' />} />
        <Route path="/categories" exact element={<DefaultLayout title='Categories' />} />
        <Route path="/category-item/:id/:status" exact element={<DefaultLayout title='Category Items' />} />
        <Route path="/catalog" exact element={<DefaultLayout title='Catalog' />} />
        <Route path="/sales-reports" exact element={<DefaultLayout title='Sales Reports' />} />
        <Route path="/edit-profile" exact element={<DefaultLayout title='Profile' />} />
        <Route path="*" exact={true} element={<NotFound />} />

      </Routes>
    </Router>

  );
}






export default App;
