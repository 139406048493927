import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import firebase from '../firebase';
import SweetAlert from 'react-bootstrap-sweetalert';
import { baseURL } from './baseURL';
import Papa from "papaparse";
import { toast, ToastContainer } from 'react-toastify';
import * as Excel from "exceljs/dist/exceljs.min.js";
import { saveAs } from 'file-saver';

const Items = () => {
    const [resto, setResto] = useState({});
    const [deleteId, setDeleteId] = useState('');
    const [DeleteItemCatId, setDeleteItemCatId] = useState('');
    const [itemsList, setItemList] = useState([]);
    const [categories, setCategories] = useState([]);
    const [displayItems, setDisplayItems] = useState([]);
    const rows = [5, 10, 20, 30];
    const [itemsPerPage, setItemsPerPage] = useState(rows[0]);
    const [currentPage, setCurrentPage] = useState(1);
    const [alert, setAlert] = useState(false);
    const [alert2, setAlert2] = useState(false);
    const [loading, setLoading] = useState(true);


    const [parsedData, setParsedData] = useState([]);

    //State to store table Column name
    const [tableRows, setTableRows] = useState([]);




    // const handleOnChange = (e) => {
    //     setFile(e.target.files[0]);
    // };

    // useEffect(()=>{
    //     if (file) {
    //         fileReader.onload = function (event) {
    //           const text = event.target.result;
    //           csvFileToArray(text);
    //         };

    //         fileReader.readAsText(file);
    //       }
    // },[file]);
    // const csvFileToArray = string => {
    //     const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    //     const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    //     const array = csvRows.map(i => {
    //       const values = i.split(",");
    //       const obj = csvHeader.reduce((object, header, index) => {
    //         object[header] = values[index];
    //         return object;
    //       }, {});
    //       return obj;
    //     });

    //     setArray(array);
    //     console.log(array);
    // };


    // const headerKeys = Object.keys(Object.assign({}, ...array));
    const handleOnChange = async (event) => {
        String.prototype.equalsIgnoreCase = function (compareString) { return this.toLowerCase().trim() === compareString; };
        // Passing file data (event.target.files[0]) to parse using Papa.parse
        if (event.target.files[0].type !== "text/csv") {
            toast.error("Please select only CSV file");
            return;
        }
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                const rowsArray = [];
                var valuesArray = [];
                let removeIndex = [];
                var errValueArray = [];

                // Iterating data to get column name and their values
                results.data.map((d) => {
                    rowsArray.push(Object.keys(d));
                    valuesArray.push(Object.values(d));
                });

                valuesArray.map((val, idx) => {
                    let error = false;
                    let erroMsg;
                    if (val.includes("")) {
                        erroMsg = "Enter all required values";
                        error = true;
                    }
                    else if (isNaN(val[3])) {
                        erroMsg = "Invalid Price";
                        error = true;
                    }
                    else if (!categories.find((cat) => { return cat.cat_name.toLowerCase().trim() == val[4].toLowerCase().trim() })) {
                        erroMsg = "Invalid Category";
                        error = true;
                    }
                    else if (!(val[5].toString().toLowerCase().trim() == "yes" || val[5].toString().toLowerCase().trim() == "no")) {
                        erroMsg = "Invalid option for stock availability";
                        error = true;
                    }
                    else if (!(val[6].toString().toLowerCase().trim() == "yes" || val[6].toString().toLowerCase().trim() == "no")) {
                        erroMsg = "Invalid option for popular item.";
                        error = true;
                    }

                    if (error == true) {
                        val[7] = erroMsg;
                        removeIndex.push(idx);
                        errValueArray.push(val);

                    }

                });

                valuesArray = valuesArray.filter(function (val) {
                    return !errValueArray.find((val2) => {
                        return (val[0] === val2[0] && val[1] === val2[1])
                    })
                });


                if (valuesArray.length > 0) {
                    addItems(valuesArray, errValueArray);
                    downloadExcel(errValueArray);
                }
                else {
                    toast.error('No enough rows for add');
                }

            },
        })
    };
    async function addItems(rows, errorRows) {
        let restaurant_id = localStorage.getItem('CB_settings_ID');

        rows.map((row) => {
            let categoryArr = categories.find((cat) => { return cat.cat_name.toLowerCase().trim() == row[4].toLowerCase().trim() });

            firebase.firestore().collection('items').add({
                added_on: Date.now().toString(),
                category_id: categoryArr.cat_id,
                item_available_in_stock: (row[5].toString().toLowerCase().trim() == "yes") ? true : false,
                item_description: row[2],
                item_image: null,
                item_name: row[1],
                item_price: row[3],
                item_quantity: 0,
                item_show_in_popular: (row[6].toString().toLowerCase().trim() == "yes") ? true : false,
                item_type: 0,
                restaurant_id: restaurant_id
            }).then(async docRef => {

                const ref = firebase.firestore()
                    .collection('restaurants').doc(restaurant_id)
                    .collection('arrange_menu_items').doc(localStorage.getItem(restaurant_id));
                const doc = await ref.get();
                if (doc.exists) {
                    ref.update({
                        [categoryArr.cat_id]: firebase.firestore.FieldValue.arrayUnion(docRef.id)
                    }).catch((e) => {
                        console.log(e);
                    });
                }
                else {
                    ref.set({
                        [categoryArr.cat_id]: firebase.firestore.FieldValue.arrayUnion(docRef.id)
                    }).catch((e) => {
                        console.log(e);
                    });
                }

            });

        });
        if (errorRows.length == 0)
            toast.success("CSV file imported successfully");
        else if (errorRows.length > 0)
            toast.success("CSV file imported partially");
        getCategoryList();
    }
    useEffect(() => {
        getCategoryList();
        getProfile();
    }, []);

    const getProfile = () => {
        firebase.firestore().collection('restaurants').doc(localStorage.getItem('CB_settings_ID')).get().then(doc => {
            setResto(doc.data());
            // setLoading(false);
        })
    }

    const getCategoryList = () => {
        firebase.firestore().collection('categories').where('is_enable', '==', true)
            .get().then(querySnapshot => {
                let arr = [];
                querySnapshot.forEach(doc => {
                    arr.push({ cat_id: doc.id, cat_name: doc.data().category_name, cat_image: doc.data().category_image })
                });
                setCategories(arr.reverse());
                getItemList(arr.reverse());
            })
    }

    const getItemList = async (cat_arr) => {
        setLoading(true);
        await firebase.firestore().collection('items').where('restaurant_id', '==', localStorage.getItem('CB_settings_ID'))
            .get().then(snapshot => {
                let arr = [];
                // let index = 0;
                snapshot.forEach((doc) => {
                    let cat_name = '';
                    cat_arr.map((elem) => {
                        if (elem.cat_id === doc.data().category_id) {
                            cat_name = elem.cat_name
                        }
                    })
                    // index += 1;
                    arr.push({ ...doc.data(), category_name: cat_name, item_id: doc.id })
                })
                setItemList(arr.sort((a, b) => Number(b.added_on) - Number(a.added_on)));
                setLoading(false)
            })
    }

    useEffect(() => {
        setDisplayItems(itemsList)
    }, [itemsList]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // const currentItems = displayItems.slice(indexOfFirstItem, indexOfLastItem);
    const currentItems = displayItems;
    const lastPageNumber = Math.ceil(displayItems.length / itemsPerPage);

    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(displayItems.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    const paginate = (num) => {
        //setCurrentPage(num);
    }

    const handleSearch = (e) => {
        if (e.target.value) {
            let search = e.target.value;
            let arr = itemsList.filter(
                (elem) =>
                    elem.item_name.toLowerCase().includes(search.toLowerCase()) ||
                    elem.category_name.toLowerCase().includes(search.toLowerCase())
            );
            let arr2 = [];
            let index = 0;
            arr.map((elem) => {
                index += 1;
                arr2.push({ ...elem, index });
            })
            setDisplayItems(arr2);
        } else {
            setDisplayItems(itemsList)
        }
    }

    const add3Dots = (string, limit) => {
        var dots = "...";
        if (string.length > limit) {
            string = string.substring(0, limit) + dots;
        }
        return string;
    }

    const handleDeleteItem = (id, cat_id) => {
        console.log(id);
        setDeleteId(id);
        setDeleteItemCatId(cat_id);
        setAlert(true);
    }

    const handleDuplicateItem = async (itemDetails) => {
        await firebase
            .firestore()
            .collection("items")
            .add({
                added_on: Date.now().toString(),
                category_id: itemDetails.category_id,
                item_available_in_stock: itemDetails.item_available_in_stock,
                item_description: itemDetails.item_description || "",
                item_image: itemDetails.item_image,
                item_name: itemDetails.item_name,
                item_price: itemDetails.item_price,
                item_quantity: 0,
                item_show_in_popular: itemDetails.item_show_in_popular,
                item_type: 0,
                restaurant_id: localStorage.getItem("CB_settings_ID"),
            })
            .then(async (docRef) => {
                console.log(docRef.id);
                const ref = await firebase
                    .firestore()
                    .collection("restaurants")
                    .doc(localStorage.getItem("CB_settings_ID"))
                    .collection("arrange_menu_items")
                    .doc(localStorage.getItem("CB_settings_ID"));
                const doc = await ref.get();
                if (doc.exists) {
                    await ref
                        .update({
                            [itemDetails.category_id]: firebase.firestore.FieldValue.arrayUnion(
                                docRef.id
                            ),
                        })
                        .catch((e) => {
                            console.log(e);
                        });
                } else {
                    await ref
                        .set({
                            [itemDetails.category_id]: firebase.firestore.FieldValue.arrayUnion(
                                docRef.id
                            ),
                        })
                        .catch((e) => {
                            console.log(e);
                        });
                }
                let modifier_groups = await firebase
                    .firestore()
                    .collection("items").doc(itemDetails.item_id).collection("item_modifier").doc(itemDetails.item_modifier_id).get().then(doc => {
                        return doc.data().modifier_groups;
                    })
                await firebase
                    .firestore()
                    .collection("items")
                    .doc(docRef.id)
                    .collection("item_modifier")
                    .add({
                        added_on: Date.now().toString(),
                        item_id: docRef.id,
                        item_name: itemDetails.item_name,
                        modifier_groups,
                    })
                    .then((doc) => {
                        firebase
                            .firestore()
                            .collection("items")
                            .doc(docRef.id)
                            .collection("item_modifier")
                            .doc(doc.id)
                            .update({
                                id: doc.id,
                            });
                        firebase.firestore().collection("items").doc(docRef.id).update({
                            item_id: docRef.id,
                            item_modifier_id: doc.id,
                        });
                        toast.success("Item Dupliacted Successfully");
                        getItemList(categories);
                    });
            });
    }

    const DeleteItem = () => {
        //console.log(deleteId);
        firebase.firestore().collection('items').doc(deleteId).delete().then(() => {

            const ref = firebase.firestore()
                .collection('restaurants').doc(localStorage.getItem('CB_settings_ID'))
                .collection('arrange_menu_items').doc(localStorage.getItem('CB_settings_ID'))
                .update({
                    [DeleteItemCatId]: firebase.firestore.FieldValue.arrayRemove(deleteId)
                }).catch((e) => {
                    console.log(e);
                });
            setDeleteId('');
            setAlert(false);
            setAlert2(true);
            getCategoryList();
        })
    }
    const downloadExcel = (async (finalArr) => {
        let wb = new Excel.Workbook();
        const ws = wb.addWorksheet();
        ws.columns = [
            { header: 'Srno', key: '0' },
            { header: 'Name', key: '1' },
            { header: 'Description', key: '2' },
            { header: 'Price', key: '3' },
            { header: 'Category', key: '4' },
            { header: 'Instock(Yes/No)', key: '5' },
            { header: 'Show In Popular Item(Yes/No)', key: '6' },
            { header: 'Error', key: '7' }
        ];
        ws.columns = [{ width: 15 }, { width: 15 }, { width: 15 }, { width: 15 }, { width: 15 }, { width: 15 }, { width: 25 }]
        ws.getCell('A1').font = { bold: true };
        ws.getCell('B1').font = { bold: true };
        ws.getCell('C1').font = { bold: true };
        ws.getCell('D1').font = { bold: true };
        ws.getCell('E1').font = { bold: true };
        ws.getCell('F1').font = { bold: true };
        ws.getCell('G1').font = { bold: true };
        ws.getCell('H1').font = { bold: true };

        ws.addRows(finalArr);
        const buf = await wb.xlsx.writeBuffer()
        saveAs(new Blob([buf]), `error.xlsx`)
    });

    return (
        <>
            <ToastContainer />
            <div className="customers-product-order" style={{ overflowY: "auto" }}>
                <div className="customers-product-order-search">
                    <div className="customers-product-order-input">
                        <input type="text" className="form-control" placeholder="search items" onChange={handleSearch} />
                    </div>
                    <div className="customers-product-top-btn">
                        <label className="btn btn-success" style={{ marginRight: "10px" }}>Import CSV
                            <input className="btn btn-success" style={{ display: "none" }} type={"file"} id={"csvFileInput"} accept={".csv"} onChange={handleOnChange} placeholder="dsd" />
                        </label>
                        {/* <button  style={{ marginRight: "10px" }}>Import CSV</button> */}
                        <Link to='/add-item' className="btn btn-success">Add New</Link>
                    </div>
                </div>
                <div className="customers-product-order-list">
                    <div className="table-responsive">
                        <table className="table mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">Sr. No.</th>
                                    <th scope="col">Item Name</th>
                                    <th scope="col">Item Image</th>
                                    <th scope="col">Item Category</th>
                                    <th scope="col">Item Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    !loading ?
                                        (
                                            currentItems.length > 0 ? currentItems.map((elem, index) =>
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td style={{ textOverflow: "ellipsis", whiteSpace: "break-spaces" }}>{elem.item_name}</td>
                                                    <td>{elem.item_image ? <img src={elem.item_image} alt='item-img' style={{ width: "70px", height: "50px", objectFit: "cover" }} /> : <img src={`${baseURL}assets/images/placeholderImg1.png`} alt='item-img' style={{ width: "50px", height: "50px", objectFit: "cover" }} />}</td>
                                                    <td>{elem.category_name}</td>
                                                    <td className="product-order-spending"> <span>{resto?.currency_symbol ?? '$'} {elem.item_price}</span></td>
                                                    <td>
                                                        <div className="dropdown">
                                                            <button className="btn p-0" id="dropdownMenuLink"
                                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                                <img src={`${baseURL}assets/images/more.png`} alt="" className="img-fluid" />
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                <li><Link to='/edit-item' className="dropdown-item" state={{ item_id: elem.item_id }}>Edit Item</Link></li>
                                                                <li><a className="dropdown-item" onClick={() => handleDuplicateItem(elem)} style={{ cursor: "pointer" }}>Duplicate Item</a></li>
                                                                <li><a className="dropdown-item" onClick={() => handleDeleteItem(elem.item_id, elem.category_id)} style={{ cursor: "pointer" }}>Delete Item</a></li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) : <tr><td colSpan="5"><strong>No Record Found</strong></td></tr>
                                        )
                                        : <tr><td colSpan="5">Loading...</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                {currentItems.length > 0 ? <div className="product-order-pages">
                    <h6 className="product-order-page-title">Showing {currentItems.length} of {displayItems.length} Data</h6>
                    <nav aria-label="Page navigation example">
                        <ul className="pagination">
                            <li className="page-item">
                                <button className="page-link-btn page-link-prev" disabled={currentPage === 1} onClick={() => paginate(currentPage - 1)}>Prev</button>
                            </li>
                            <li className="page-item ">
                                <a className="page-link page-link-number active">{currentPage}</a>
                            </li>
                            <li className="page-item">
                                <button className="page-link-btn page-link-next" disabled={currentPage === lastPageNumber} onClick={() => paginate(currentPage + 1)}>Next</button>
                            </li>
                        </ul>
                    </nav>
                </div> : ''}
            </div>
            {alert === true ? <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                title="Are you sure?"
                onConfirm={DeleteItem}
                onCancel={() => { setAlert(false); setDeleteId(''); }}
            >
                You will not be able to recover this item!
            </SweetAlert> : ''
            }
            {alert2 === true ? <SweetAlert
                success
                title="Item Deleted Successfully!"
                onConfirm={() => { setAlert2(false); getCategoryList(); }}
            />
                : ''}
        </>
    )
}

export default Items