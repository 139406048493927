import React, { useEffect, useState } from 'react';
import firebase from '../firebase';
import { storage } from '../firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { toast, ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import uuid from 'react-native-uuid';
import CropEasy from './cropEasy';

const EditItem = () => {
    const [resto, setResto] = useState({});
    const navigate = useNavigate();
    const location = useLocation();
    const [categories, setCategories] = useState([]);
    const [itemDetails, setItemDetails] = useState({
        item_name: "",
        item_description: "",
        item_price: "",
        item_available_in_stock: true,
        item_show_in_popular: true,
    });
    const [originalCategory, setOriginalCategory] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [disable, setDisable] = useState(false);
    const [imgErr, setImgErr] = useState('');
    const [modifierErr, setModifierErr] = useState({});
    const [groupErr, setGroupErr] = useState({});
    const [itemErr, setItemErr] = useState({});
    const [image, setImage] = useState('');
    const [progress, setProgress] = useState('');
    // const [modifierName, setModiferName] = useState('');
    // const [price, setPrice] = useState('');
    const [modifiers, setModifiers] = useState([{ id: uuid.v4(), modifier_name: '', set_price: 0, is_enable: true }]);
    const [enableModifier, setEnableModifier] = useState(true);
    const [groupName, setGroupName] = useState('');
    const [required, setRequired] = useState(true);
    const [selectAs, setSelectAs] = useState('Single');
    const [groups, setGroups] = useState([]);
    const [id, setId] = useState('');
    const [photoURL, setPhotoURL] = useState('');
    const [openCrop, setOpenCrop] = useState(false);
    const [photoName, setPhotoName] = useState('');
    const [file, setFile] = useState(null);
    const [status, setStatus] = useState('Done');
    const [modifierSuggestions, setModifierSuggestions] = useState([]);

    useEffect(() => {
        getItemDetails();
        getModifiersList();
        getProfile();
    }, []);

    const getProfile = () => {
        firebase.firestore().collection('restaurants').doc(localStorage.getItem('CB_settings_ID')).get().then(doc => {
            setResto(doc.data());
            // setLoading(false);
        })
    }
    useEffect(() => {
        (progress !== "") ? setDisable(true) : setDisable(false);
    }, [progress]);
    const handleItems = (e) => {
        const newDetails = itemDetails;
        newDetails[e.target.name] = e.target.value;
        setItemDetails(newDetails);
    };

    const handleImage = (e) => {
        let img = e.target.files[0];
        if (img.type === 'image/jpeg' || img.type === 'image/jpg' || img.type === 'image/png') {
            setFile(img);
            setPhotoURL(URL.createObjectURL(img));
            setOpenCrop(true)
            // setImage(img);
        } else {
            setImgErr('Incorrect Image Format.')
        }
    }

    function blobToFile(theBlob, fileName) {
        return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
    }

    const croppedImage = (file, url) => {
        var myFile = blobToFile(file, file.name);
        console.log('file', myFile);
        setImgErr('');
        setProgress(0);
        console.log(myFile);
        const storageRef = ref(storage, `item_images/${Date.now()}`);
        const uploadTask = uploadBytesResumable(storageRef, myFile);

        uploadTask.on('state_changed',
            (snapshot) => {
                const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgress(progress);
            },
            (error) => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(url => {
                    setImage(url);
                    setProgress('')
                })
            }
        )

    }

    const getItemDetails = () => {
        firebase.firestore().collection('items').doc(location.state.item_id).get().then(doc => {
            setItemDetails({
                item_name: doc.data().item_name,
                item_description: doc.data().item_description,
                item_price: doc.data().item_price,
                item_available_in_stock: doc.data().item_available_in_stock,
                item_show_in_popular: doc.data().item_show_in_popular,
            });
            setOriginalCategory(doc.data().category_id);
            setCategoryId(doc.data().category_id)
            setImage(doc.data().item_image)
            firebase.firestore().collection('items').doc(location.state.item_id).collection('item_modifier').doc(doc.data().item_modifier_id).get().then(document => {
                let group_arr = [];
                document.data().modifier_groups.map(elem => {
                    group_arr.push({ id: uuid.v4(), ...elem });
                })
                setGroups(group_arr);
            })
        });

    }
    const getModifiersList = () => {
        firebase.firestore().collection('modifiers').where('is_enable', '==', true)
            .get().then(querySnapshot => {
                let arr = [];
                querySnapshot.forEach(doc => {
                    arr.push({ grp_name: doc.data().group_name, modifier: doc.data().modifiers.map((mod) => { return mod }) })
                });
                setModifierSuggestions(arr);
            })
    }

    const handleModifierSuggestion = (elem) => {
        setGroupName(elem.grp_name);
        let arr = [];
        elem.modifier.map((mod) => {
            arr.push({ id: uuid.v4(), modifier_name: mod, set_price: 0, is_enable: true })
        })
        setModifiers(arr);
        setStatus('Suggestions')
    }


    useEffect(() => {
        getCategoryList();
    }, [])

    const getCategoryList = () => {
        firebase.firestore().collection('categories').where('is_enable', '==', true)
            .get().then(querySnapshot => {
                let arr = [];
                querySnapshot.forEach(doc => {
                    arr.push({ cat_id: doc.id, cat_name: doc.data().category_name, cat_image: doc.data().category_image })
                });
                setCategories(arr.reverse());
            })
    }

    const handleModifier = (e, id) => {
        const newDetails = modifiers.map((obj) => {
            if (obj.id === id) {
                if (e.target.name === "modifier_name") {
                    return { ...obj, modifier_name: e.target.value };
                } else {
                    return { ...obj, set_price: parseFloat(e.target.value) };
                }
            }
            return obj;
        });
        setModifiers(newDetails);
    }

    const validateModifier = () => {
        let isValid = true;
        let err = {};

        if (!modifiers[modifiers.length - 1].modifier_name) {
            isValid = false;
            err[`modifier_name_${modifiers.length - 1}_err`] = "Please enter modifier name"
        }

        if (modifiers[modifiers.length - 1].set_price < 0) {
            isValid = false;
            err[`set_price_${modifiers.length - 1}_err`] = "Please enter price"
        }

        setModifierErr(err);
        return isValid;
    }

    const addModifier = () => {
        if (validateModifier()) {
            setModifierErr({});
            setModifiers([...modifiers, { id: uuid.v4(), modifier_name: "", set_price: 0, is_enable: true }])
        }
    }

    const handleDeleteModifier = (id) => {
        setModifiers(modifiers.filter(mod => mod.id !== id))
    }

    const validateGroup = () => {
        let isValid = true;
        let err = {};
        let err2 = {};

        if (!groupName) {
            isValid = false;
            err['group_name_err'] = "Please enter group name"
        }

        // if (groups.length > 0) {
        //     if (groups.every(mod => mod.group_name.toLowerCase() === groupName.toLowerCase())) {
        //         isValid = false;
        //         err['group_name_err'] = "Group name already exists"
        //     }
        // }

        if (modifiers.length === 1) {
            if (!modifiers[0].modifier_name || modifiers[0].set_price < 0) {
                isValid = false;
                err['modifier_err'] = "Please add atleast one modiifer"
            }
        } else {
            modifiers.map((mod, index) => {
                if (!mod['modifier_name']) {
                    isValid = false;
                    err2[`modifier_name_${index}_err`] = "Please enter modifier name"
                }
                if (mod['set_price'] === "" || mod['set_price'] === null) {
                    isValid = false;
                    err2[`set_price_${index}_err`] = "Please enter price"

                }
                else if (isNaN(mod['set_price']) || mod['set_price'] < 0) {
                    isValid = false;
                    err2[`set_price_${index}_err`] = "Please enter valid price"
                }
            })
        }
        setGroupErr(err);
        setModifierErr(err2);

        setGroupErr(err);
        return isValid;
    }

    const addGroup = () => {
        if (validateGroup()) {
            let modifier_arr = [];
            modifiers.map((elem) => {
                modifier_arr.push({ modifier_name: elem.modifier_name, set_price: elem.set_price, is_enable: elem.is_enable })
            })
            console.log(modifier_arr);
            setGroupErr({});
            setGroups([...groups, { id: uuid.v4(), enable_modifier: enableModifier, group_name: groupName, modifiers: modifier_arr, required: required, select_as: selectAs }]);
            setModifiers([{ modifier_name: '', set_price: 0, is_enable: true }])
            setGroupName('');
            setEnableModifier(true);
            setRequired(true);
            setSelectAs('Single');
            setStatus('Done');
        }
    }


    const handleUpdateGrp = () => {
        if (validateGroup()) {
            setGroupErr({});
            let modifier_arr = [];
            modifiers.map((elem) => {
                modifier_arr.push({ modifier_name: elem.modifier_name, set_price: parseFloat(elem.set_price), is_enable: elem.is_enable })
            })
            const finalGroup = groups.map(obj => {
                if (obj.id === id) {
                    return {
                        ...obj, enable_modifier: enableModifier, group_name: groupName, modifiers: modifier_arr, required: required, select_as: selectAs
                    }
                }
                return obj;
            })
            setGroups(finalGroup);
            setId('');
            setStatus('Done');
            setGroupName('');
            setEnableModifier(true);
            setRequired(true);
            setSelectAs('Single');
            setModifiers([{ modifier_name: '', set_price: 0, is_enable: true }])
        }
    }

    const validateItems = () => {
        let isValid = true;
        let err = {};
        let input = itemDetails;

        // if (!image) {
        //     isValid = false;
        //     err['item_image_err'] = "Please select item image"
        // }

        if (!input['item_name']) {
            isValid = false;
            err['item_name_err'] = "Please enter item name"
        }

        if (!input['item_description']) {
            isValid = false;
            err['item_description_err'] = "Please enter item description"
        }

        if (!input['item_price'] || isNaN(input['item_price']) || parseInt(input['item_price']) < 0) {
            isValid = false;
            if (parseInt(input['item_price']) < 0)
                err['item_price_err'] = "Negative values are invalid"
            else
                err['item_price_err'] = "Please enter item price"
        }

        if (!categoryId) {
            isValid = false;
            err['category_err'] = "Please select one catgeory"
        }

        setItemErr(err);
        return isValid;
    }

    const handleEdit = (element) => {
        setId(element.id);
        setStatus('Update');
        setGroupName(element.group_name);
        setEnableModifier(element.enable_modifier);
        setRequired(element.required);
        setSelectAs(element.select_as);
        let modifer_arr = [];
        element.modifiers.map((elem) => {
            modifer_arr.push({ id: uuid.v4(), ...elem })
        })
        setModifiers(modifer_arr);
        setStatus("Update");
    }

    const handleCancel = () => {
        setId('');
        setStatus('Done');
        setGroupName('');
        setEnableModifier(true);
        setRequired(true);
        setSelectAs('Single');
        setModifiers([{ modifier_name: '', set_price: 0, is_enable: true }])
    }

    const handleUpdateGroup = () => {
        if (validateGroup()) {
            setGroupErr({});
            let modifier_arr = [];
            modifiers.map((elem) => {
                modifier_arr.push({ modifier_name: elem.modifier_name, set_price: elem.set_price, is_enable: elem.is_enable })
            })
            const finalGroup = groups.map(obj => {
                if (obj.id === id) {
                    return {
                        ...obj, enable_modifier: enableModifier, group_name: groupName, modifiers: modifier_arr, required: required, select_as: selectAs
                    }
                }
                return obj;
            })
            setGroups(finalGroup);
            setId('');
            setStatus('Done');
            setGroupName('');
            setEnableModifier(true);
            setRequired(true);
            setSelectAs('Single');
            setModifiers([{ modifier_name: '', set_price: 0, is_enable: true }])
        }
    }

    const handleUpdate = (e) => {
        e.preventDefault();
        if (validateItems()) {
            setDisable(true);
            firebase.firestore().collection('items').doc(location.state.item_id).update({
                category_id: categoryId,
                item_available_in_stock: itemDetails.item_available_in_stock,
                item_description: itemDetails.item_description,
                item_image: (image) ? image : null,
                item_name: itemDetails.item_name,
                item_price: itemDetails.item_price,
                item_show_in_popular: itemDetails.item_show_in_popular,
            }).then(async (docRef) => {

                if (originalCategory !== categoryId) {
                    const ref = firebase.firestore()
                        .collection('restaurants').doc(localStorage.getItem('CB_settings_ID'))
                        .collection('arrange_menu_items').doc(localStorage.getItem('CB_settings_ID'));
                    const doc = await ref.get();
                    if (doc.exists) {
                        ref.update({
                            [originalCategory]: firebase.firestore.FieldValue.arrayRemove(location.state.item_id),
                            [categoryId]: firebase.firestore.FieldValue.arrayUnion(location.state.item_id)
                        }).catch((e) => {
                            console.log(e);
                        });
                    }
                    else {
                        ref.set({
                            [categoryId]: firebase.firestore.FieldValue.arrayUnion(location.state.item_id)
                        }).catch((e) => {
                            console.log(e);
                        });
                    }
                }
            })
            //if (groups.length > 0) {
            let grp_arr = [];
            groups.map((elem) => {
                grp_arr.push({ enable_modifier: elem.enable_modifier, group_name: elem.group_name, modifiers: elem.modifiers, required: elem.required, select_as: elem.select_as })
            });
            firebase.firestore().collection('items').doc(location.state.item_id).collection('item_modifier').get().then(querySnapshot => {
                if (querySnapshot.docs.length > 0) {
                    querySnapshot.forEach(doc => {
                        firebase.firestore().collection('items').doc(location.state.item_id).collection('item_modifier').doc(doc.id).update({
                            item_name: itemDetails.item_name,
                            modifier_groups: grp_arr
                        })

                    })
                    setDisable(false);
                    toast.success("Item Updated Successfully");
                    setTimeout(() => {
                        navigate('/items');
                    }, [2000])
                } else {
                    firebase.firestore().collection('items').doc(location.state.item_id).collection('item_modifier').add({
                        added_on: Date.now().toString(),
                        item_id: location.state.item_id,
                        item_name: itemDetails.item_name,
                        modifier_groups: grp_arr
                    }).then(doc => {
                        firebase.firestore().collection('items').doc(location.state.item_id).collection('item_modifier').doc(doc.id).update({
                            id: doc.id
                        });
                        firebase.firestore().collection('items').doc(location.state.item_id).update({
                            item_id: location.state.item_id,
                            item_modifier_id: doc.id
                        })
                        setDisable(false);
                        toast.success("Item Updated Successfully");
                        setTimeout(() => {
                            navigate('/items');
                        }, [2000])
                    })
                }
            })
            // } else {
            //     console.log("no groups");
            //     firebase.firestore().collection('items').doc(location.state.item_id).update({
            //         item_modifier_id: ''
            //     });
            //     firebase.firestore().collection('items').doc(location.state.item_id).collection('item_modifier').get().then((snapshot) => {
            //         snapshot.forEach((doc) => {
            //             firebase.firestore().collection('items').doc(location.state.item_id).collection('item_modifier').doc(doc.id).delete();
            //         })
            //     })
            //     setDisable(false);
            //     toast.success("Item Updated Successfully");
            //     setTimeout(() => {
            //         navigate('/items');
            //     }, [2000])
            // }
        }
    }

    const handleChangeModiifer = (val, indexs) => {
        const newDetails = modifiers.map((obj, index) => {
            if (index === indexs) {
                return {
                    ...obj, is_enable: !val
                }
            }
            return obj;
        });
        setModifiers(newDetails)
    }
    const handleClose = () => {
        setOpenCrop(false);
    }

    return (
        <>
            <ToastContainer />
            <CropEasy
                photoURL={photoURL}
                file={file}
                activeModal={openCrop}
                setActiveModal={handleClose}
                croppedImage={croppedImage}
            />
            <div className="food-add-item customers-product-order" data-scrollbar style={{ overflow: 'hidden', outline: 'none ', overflowY: "auto" }}>
                {/* <div className="customers-product-order-search">
                    <h2 className="food-add-item-title">Edit Item</h2>
                    <div className="customers-product-top-btn food-item-top-btn">
                        <button disabled={disable} className="btn btn-success" onClick={handleUpdate}>{disable ? 'Processing' : 'Save Item'}</button>
                    </div>
                </div> */}
                <div className="food-items-form">
                    <div className="row">
                        <div className="col col-12 col-md-12 col-lg-6 col-xl-6">
                            <div className="food-item-details">
                                <div className="food-details-heading">
                                    <h4 className="food-details-title">Items Details</h4>
                                </div>
                                <div className="food-details-form">
                                    <div className="food-upload-img">
                                        {!image ? <div className="food-upload-browse">
                                            {!progress ? <label className="fomr-label">Upload Item Image</label> : <label className="fomr-label">Uploading .... {progress}%</label>}

                                            <input type="file" onChange={handleImage} />
                                        </div>
                                            : <div style={{ display: "flex", justifyContent: "flex-end", paddingRight: "0px", position: "relative" }}>
                                                <img src={image} alt="item-img" style={{ width: "240px", height: "200px", objectFit: "contain", borderRadius: "10px" }} />
                                                <div style={{ position: "absolute", right: "-20px", top: "10px", cursor: "pointer" }} onClick={() => setImage('')}>
                                                    <span className='cancel-icon'><strong>X</strong></span>
                                                </div>
                                            </div>}
                                        <div className='error' style={{ textAlign: "right" }}>{imgErr}</div>
                                        <div className='error' style={{ textAlign: "right" }}>{itemErr.item_image_err}</div>
                                        <div style={{ textAlign: "right", color: "orange" }}>Recommanded size 1920*1080</div>
                                    </div>
                                    <div className="food-details-input">
                                        <label className="form-label">Item Name</label>
                                        <div>
                                            <input type="text" className="form-control" placeholder="Enter Item Name" name="item_name" onChange={handleItems} defaultValue={itemDetails.item_name} />
                                            <div className='error'>{itemErr.item_name_err}</div>
                                        </div>
                                    </div>
                                    <div className="food-details-input food-details-discription">
                                        <label className="form-label">Description</label>
                                        <div>
                                            <textarea className="form-control" cols="30" rows="5"
                                                placeholder="Description" name="item_description" onChange={handleItems} defaultValue={itemDetails.item_description}></textarea>
                                            <div className='error'>{itemErr.item_description_err}</div>
                                        </div>
                                    </div>
                                    <div className="food-details-input">
                                        <label className="form-label">Price</label>
                                        <div>
                                            <input type="number" className="form-control" placeholder={resto?.currency_symbol ?? '$'} name="item_price" min={0} onChange={handleItems} defaultValue={itemDetails.item_price} onWheel={(e) => e.target.blur()} />
                                            <div className='error'>{itemErr.item_price_err}</div>
                                        </div>
                                    </div>
                                    <div className="food-details-input mb-0">
                                        <label className="form-label">Category</label>
                                        <div>
                                            <select className='form-check' style={{ width: "240px", height: "50px", borderRadius: "8px" }} name="category_id" onChange={(e) => setCategoryId(e.target.value)} value={categoryId}>
                                                <option style={{ display: 'none' }} value="">Select Category</option>
                                                {categories.map(elem =>
                                                    <option value={elem.cat_id} key={elem.cat_id}>{elem.cat_name}</option>
                                                )}
                                            </select>

                                            <div className='error'>{itemErr.category_err}</div>

                                        </div>
                                    </div>
                                    <div className="category-available">
                                        <div className="form-check">
                                            <label className="form-check-label" htmlFor="stockCheck1">
                                                Available In Stock
                                            </label>
                                            <input className="form-check-input" type="checkbox" checked={itemDetails.item_available_in_stock}
                                                id="stockCheck1" onChange={() => setItemDetails({ ...itemDetails, item_available_in_stock: !itemDetails.item_available_in_stock })} />
                                        </div>
                                        <div className="form-check">
                                            <label className="form-check-label" htmlFor="stockCheck2">
                                                Show In Popular Item
                                            </label>
                                            <input className="form-check-input" type="checkbox" checked={itemDetails.item_show_in_popular}
                                                id="stockCheck2" onChange={() => setItemDetails({ ...itemDetails, item_show_in_popular: !itemDetails.item_show_in_popular })} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="food-category food-item-details">
                                <div className="food-category-heading food-details-heading">
                                    <h4 className="food-details-title">item Category</h4>
                                </div>
                                <div className="food-category-form food-details-form">
                                    <div className="form-check food-category-chekbox">
                                        <select className='form-check' style={{ width: "300px", height: "50px", borderRadius: "8px" }} name="category_id" onChange={(e) => setCategoryId(e.target.value)} value={categoryId}>
                                            <option style={{ display: 'none' }} value="">Select Category</option>
                                            {categories.map(elem =>
                                                <option value={elem.cat_id} key={elem.cat_id}>{elem.cat_name}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className='error'>{itemErr.category_err}</div>
                                </div>
                            </div> */}
                        </div>
                        <div className="col col-12 col-md-12 col-lg-6 col-xl-6">
                            <div className="food-other-item food-item-details" style={{ marginBottom: "25px" }}>
                                <div className="food-details-heading">
                                    <h4 className="food-details-title">Modifier Group</h4>
                                </div>
                                <div className='suggestions'>
                                    <div className='row'>
                                        <div className='col-3'>
                                            <label>Suggestions:</label>
                                        </div>
                                        <div className='col-9 modifier-sugg' style={{ textAlign: "left" }}>
                                            {modifierSuggestions.map((elem) =>
                                                <span onClick={() => handleModifierSuggestion(elem)}>{elem.grp_name}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="food-other-item-form food-details-form">
                                    <div className="modifer-input food-details-input">
                                        <label className="form-label">Group Name</label>
                                        <div>
                                            <input type="text" className="form-control" name="group_name" value={groupName} onChange={(e) => setGroupName(e.target.value)} />
                                            <div className='error'>{groupErr.group_name_err}</div>
                                        </div>
                                    </div>
                                    {modifiers.map((elem, index) =>
                                        <div className='row' key={index} style={{ marginBottom: "10px" }}>
                                            <div className="col-sm-5 modifer-input modifier-details-input">
                                                <label className="form-label">Modifier Name</label>
                                                <input type="text" className="form-control" value={elem.modifier_name} name="modifier_name" id="modifier_name" onChange={(e) => handleModifier(e, elem.id)} />
                                                <div className='error'>{modifierErr[`modifier_name_${index}_err`]}</div>
                                            </div>
                                            <div className="col-sm-5 modifer-input modifier-details-input mb-0">
                                                <label className="form-label">Set Price</label>
                                                <input type="number" className="form-control" value={elem.set_price} name="set_price" id="set_price" min={0} onChange={(e) => handleModifier(e, elem.id)} onWheel={(e) => e.target.blur()} />
                                                <div className='error'>{modifierErr[`set_price_${index}_err`]}</div>
                                            </div>
                                            {modifiers.length > 1 && status === 'Done' ? <div className='col-sm-2 d-flex align-items-center justify-content-center' style={{ paddingTop: "10px" }}>
                                                <i className='fa fa-trash-alt' onClick={() => handleDeleteModifier(elem.id)} />
                                            </div> : ""}
                                            {status !== 'Done' ?
                                                <div className='col-sm-2 d-flex align-items-center justify-content-center' style={{ paddingTop: "10px" }}>
                                                    <div className="form-switch mb-0"><input type="checkbox" className="form-check-input border-0" checked={elem.is_enable} onChange={() => handleChangeModiifer(elem.is_enable, index)} /></div>
                                                    <i className='fa fa-trash-alt' onClick={() => handleDeleteModifier(elem.id)} />
                                                </div>
                                                : ''}
                                        </div>
                                    )}
                                    <div className='error'>{groupErr.modifier_err}</div>
                                    <div className="modifier-add-item">
                                        <span onClick={addModifier} style={{ cursor: "pointer" }}>+ Add </span>
                                    </div>
                                    <div className="modifier-item-select">
                                        <span className="modifier-select-title">Select as</span>
                                        <div className="modifier-select-btn">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="select_as"
                                                    id="modifierRadio1" checked={selectAs === 'Single'} onChange={(e) => setSelectAs(e.target.value)} value="Single" />
                                                <label className="form-check-label" htmlFor="modifierRadio1">
                                                    Single
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="select_as"
                                                    id="modifierRadio2" checked={selectAs === 'Multiple'} onChange={(e) => setSelectAs(e.target.value)} value="Multiple" />
                                                <label className="form-check-label" htmlFor="modifierRadio2">
                                                    Multiple
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modifier-switch-btn">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input border-0" type="checkbox" name="enable_modifier"
                                                id="modifierSwitch" checked={enableModifier} onChange={() => setEnableModifier(!enableModifier)} />
                                            <label className="form-check-label" htmlFor="modifierSwitch">Enable
                                                Modifier</label>
                                        </div>
                                        <div className="form-check form-switch mb-0">
                                            <input className="form-check-input border-0" type="checkbox" name="required"
                                                id="modifierSwitch1" checked={required} onChange={() => setRequired(!required)} />
                                            <label className="form-check-label" htmlFor="modifierSwitch1">Required</label>
                                        </div>
                                    </div>
                                    <div className='error'>{itemErr.group_err}</div>
                                    {status === 'Done' ?
                                        <div className="modifier-btn">
                                            <button className="btn btn-success" style={{ textTransform: "unset" }} onClick={addGroup}>Save Modifier</button>
                                        </div>
                                        : ''}
                                    {status === 'Update' ?
                                        <div className="modifier-btn">
                                            <button className="btn btn-success" style={{ marginRight: '10px', textTransform: "unset" }} onClick={handleUpdateGrp}>Update Modifier</button>
                                            <button className="btn btn-cancel" style={{ textTransform: "unset" }} onClick={handleCancel}>Cancel</button>
                                        </div>
                                        : ''}
                                    {status === 'Suggestions' ?
                                        <div className="modifier-btn">
                                            <button className="btn btn-success" onClick={addGroup} style={{ marginRight: '10px', textTransform: "unset" }}>Save Modifier</button>
                                            <button className="btn btn-cancel" style={{ textTransform: "unset" }} onClick={handleCancel}>Cancel</button>
                                        </div>
                                        : ''
                                    }
                                </div>
                                {groups.length > 0 ?
                                    <table className='table' style={{ background: "#F6F6F6" }}>
                                        <thead>
                                            <tr>
                                                <th><b>Sr. No.</b></th>
                                                <th><b>Group Name</b></th>
                                                <th><b>Action</b></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {groups.map((elem, index) =>
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{elem.group_name}</td>
                                                    <td style={{ display: "flex", justifyContent: "space-evenly" }}><i className='fa fa-edit' style={{ cursor: 'pointer' }} onClick={() => handleEdit(elem)} /><i className='fa fa-trash' style={{ cursor: 'pointer' }} onClick={() => setGroups(groups.filter(grp => grp.id !== elem.id))} /></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    : ''}
                            </div>
                            <div className="customers-product-top-btn food-item-top-btn" style={{ float: "right", width: "100%" }} >
                                <button disabled={disable} className="btn btn-success" style={{ width: "100%", padding: "0.8rem", fontWeight: "500", fontSize: "20px" }} onClick={handleUpdate}>{disable ? 'Processing' : 'Save Item'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default EditItem