import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from '../../firebase';
import { baseURL } from '../baseURL';

const Header = ({ title }) => {

    const [resto, setResto] = useState({});
    const nav = useNavigate();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getRestaurantDetails();
    }, [])

    const getRestaurantDetails = () => {
        firebase.firestore().collection('restaurants').doc(localStorage.getItem('CB_settings_ID')).get().then(doc => {
            setResto(doc.data());
            setLoading(false);
        })
    }
    return (
        <div className="customers-product-hading">
            <div className="product-menu-img">
                <img src={`${baseURL}assets/images/menu.png`} alt="" className="img-fluid" />
            </div>
            <h2 className="product-heading-title">{title}</h2>
            <div className="product-heading-profile" style={{ cursor: 'pointer' }} onClick={() => nav('/edit-profile')}>
                {!loading ? <a>
                    {resto.restaurant_image ?
                        <div className="product-heading-profile-img">
                            <img src={resto.restaurant_image} alt="" className="img-fluid" style={{ objectFit: 'cover', height: '45px', width: '45px' }} />
                        </div>
                        : <span className="product-heading-profile-img" style={{ background: '#F0F0F0', borderRadius: '15px', height: '45px', width: '45px', textAlign: 'center', fontSize: '20px', paddingTop: '7.5px' }}>{resto.restaurant_name.split(' ')[0].charAt(0).toUpperCase()}{resto.restaurant_name.split(' ')[1] ? resto.restaurant_name.split(' ')[1].charAt(0).toUpperCase() : ''}</span>}
                    <div className="product-heading-profile-name">
                        <h5>{resto.restaurant_name}</h5>
                        <span>{resto.restaurant_email}</span>
                    </div>
                </a> : ''}
            </div>
        </div>
    )
}

export default Header