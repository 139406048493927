import React, { useEffect, useState } from 'react'
import uuid from "react-native-uuid";
import { useLocation, useNavigate } from 'react-router-dom';
import firebase from '../firebase';

const ManageModifier = () => {
    const [modifiers, setModifiers] = useState([
        { id: uuid.v4(), modifier_name: "" },
    ]);
    const [groupName, setGroupName] = useState("");
    const [enableModifier, setEnableModifier] = useState(true);
    const [modifierErr, setModifierErr] = useState({});
    const [groupErr, setGroupErr] = useState({});
    const nav = useNavigate();
    const location = useLocation();
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        if (location?.state?.id) {
            firebase.firestore().collection('restaurant_modifiers').doc(location?.state?.id).get().then((doc) => {
                setGroupName(doc.data().group_name);
                setEnableModifier(doc.data().is_enable);
                setModifiers(doc.data().modifiers.map(el => {
                    return { id: uuid.v4(), modifier_name: el }
                }))
            })
        }
    }, [location])

    const handleModifier = (e, id) => {
        const newDetails = modifiers.map((obj) => {
            if (obj.id === id) {
                if (e.target.name === "modifier_name") {
                    return { ...obj, modifier_name: e.target.value };
                } else {
                    return { ...obj, set_price: parseFloat(e.target.value) };
                }
            }
            return obj;
        });
        setModifiers(newDetails);
    };

    const validateModifier = () => {
        let isValid = true;
        let err = {};

        if (!modifiers[modifiers.length - 1].modifier_name) {
            isValid = false;
            err[`modifier_name_${modifiers.length - 1}_err`] =
                "Please enter modifier name";
        }
        setModifierErr(err);
        return isValid;
    };

    const addModifier = () => {
        if (validateModifier()) {
            setModifiers([
                ...modifiers,
                {
                    id: uuid.v4(), modifier_name: "",
                },
            ]);
        }
    };

    const handleDeleteModifier = (id) => {
        setModifiers(modifiers.filter((mod) => mod.id !== id));
    };

    const validateGroup = () => {
        let isValid = true;
        let err = {};
        let err2 = {};

        if (!groupName) {
            isValid = false;
            err["group_name_err"] = "Please enter group name";
        }
        if (modifiers.length === 1) {
            if (!modifiers[0].modifier_name) {
                isValid = false;
                err["modifier_err"] = "Please add atleast one modiifer";
            }
        } else {
            modifiers.forEach((mod, index) => {
                if (!mod["modifier_name"]) {
                    isValid = false;
                    err2[`modifier_name_${index}_err`] = "Please enter modifier name";
                }
            });
        }
        setGroupErr(err);
        setModifierErr(err2);
        return isValid;
    };

    const addGroup = async () => {
        if (validateGroup()) {
            setDisable(true);
            if (!location?.state?.id) {
                await firebase.firestore().collection('restaurant_modifiers').add({
                    group_name: groupName,
                    modifiers: modifiers?.map(el => el?.modifier_name),
                    added_on: Date.now().toString(),
                    is_enable: enableModifier,
                    restaurant_id: localStorage.getItem('CB_settings_ID')
                }).then(async (docRef) => {
                    await firebase.firestore().collection('restaurant_modifiers').doc(docRef.id).update({ modifier_id: docRef.id });
                })
            } else {
                await firebase.firestore().collection('restaurant_modifiers').doc(location?.state?.id).update({
                    group_name: groupName,
                    modifiers: modifiers?.map(el => el?.modifier_name),
                    is_enable: enableModifier,
                })
            }
            nav("/modifiers");
        }
    };
    return (
        <div
            className="food-add-item customers-product-order">
            <div className="food-items-form">
                <div className="row">
                    <div className="col col-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="food-other-item-form food-details-form">
                            <div className="modifer-input food-details-input">
                                <label className="form-label">Group Name</label>
                                <div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="group_name"
                                        value={groupName}
                                        onChange={(e) => setGroupName(e.target.value)}
                                    />
                                    <div className="error">{groupErr.group_name_err}</div>
                                </div>
                            </div>
                            {modifiers.map((elem, index) => (
                                <div
                                    className="row"
                                    key={index}
                                    style={{ marginBottom: "10px" }}
                                >
                                    <div className="col-sm-6 modifer-input modifier-details-input">
                                        <label className="form-label">Modifier Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={elem.modifier_name}
                                            name="modifier_name"
                                            id="modifier_name"
                                            onChange={(e) => handleModifier(e, elem.id)}
                                        />
                                        <div className="error">
                                            {modifierErr[`modifier_name_${index}_err`]}
                                        </div>
                                    </div>
                                    {modifiers.length > 1 ? (
                                        <div
                                            className="col-sm-1 d-flex align-items-center justify-content-center"
                                            style={{ paddingTop: "10px" }}
                                        >
                                            <i
                                                className="fa fa-trash-alt"
                                                onClick={() => handleDeleteModifier(elem.id)}
                                            />
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            ))}
                            <div className="error">{groupErr.modifier_err}</div>
                            <div className="modifier-add-item">
                                <span onClick={addModifier} style={{ cursor: "pointer" }}>
                                    + Add{" "}
                                </span>
                            </div>
                            <div className="modifier-switch-btn">
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input border-0"
                                        type="checkbox"
                                        name="enable_modifier"
                                        id="modifierSwitch"
                                        checked={enableModifier}
                                        onChange={() => setEnableModifier(!enableModifier)}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="modifierSwitch"
                                    >
                                        Enable Modifier
                                    </label>
                                </div>
                            </div>
                            <div className="modifier-btn">
                                <button
                                    className="btn btn-success"
                                    style={{ textTransform: "unset" }}
                                    onClick={addGroup}
                                    disabled={disable}
                                >
                                    {disable ? "Processing" : location?.state?.id ? "Update Modifier" : "Save Modifier"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageModifier