import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import firebase from '../firebase';

const Catalogue = () => {
  const [categories, setCategories] = useState([]);
  const [adminItems, setAdminItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getCategoryList();
    getAdminItemList();
  }, []);

  const getAdminItemList = () => {
    firebase.firestore().collection('admin_items')
      .get().then(querySnapshot => {
        let arr = [];
        querySnapshot.forEach(doc => {
          arr.push({ ...doc.data() })
        });
        setAdminItems(arr);
      })
  }

  const getCategoryList = () => {
    firebase.firestore().collection('categories').where('is_enable', '==', true).where('is_admin', '==', true)
      .get().then(querySnapshot => {
        let arr = [];
        querySnapshot.forEach(doc => {
          arr.push({ cat_id: doc.id, cat_name: doc.data().category_name, cat_image: doc.data().category_image })
        });
        setCategories(arr.reverse());
      })
  }

  const add3Dots = (string, limit) => {
    var dots = "...";
    if (string.length > limit) {
      string = string.substring(0, limit) + dots;
    }
    return string;
  }

  return (
    <div class="food-add-item customers-product-order" style={{ overflowY: 'auto' }}>
      {categories.map(cat => adminItems.filter(ad => ad.category_id === cat.cat_id).length > 0 ?
        <>
          <h2 class="food-add-item-title" style={{ marginTop: "30px" }}>{cat.cat_name}</h2>
          <div class="stock-items-list s-report-form">
            <div class="row">
              {adminItems.map((elem, index) =>
                elem.category_id === cat.cat_id ?
                  <div class="col col-12 col-md-12 col-lg-6 col-xl-6">
                    <div class="stock-item" key={index} onClick={() => { navigate('/add-item', { state: { item: elem } }) }}>
                      <a href="#" class="text-decoration-none">
                        <div class="stock-item-img">
                          <img src={elem.item_image} alt="" class="img-fluid w-100 h-100" />
                        </div>
                        <div class="stock-item-details">
                          <h5 class="stock-item-title">
                            {elem.item_name}
                          </h5>
                          <p class="stock-item-discription">{elem.item_description}</p>
                        </div>
                      </a>
                    </div>
                  </div>
                  : ""
              )}
            </div>
          </div>
        </> : '')}
    </div>
  )
}

export default Catalogue