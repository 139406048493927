let url = "";
let api_url = "";
if (window.location.host === "partner.clubgrub.co") {
    url = "http://partner.clubgrub.co/";
    api_url = "https://clubgrub.co/";

} else {
    url = "http://65.2.9.81:81/";
    api_url = "http://65.2.9.81/";
}
// export const baseURL = url;
export const API_BASE_URL = api_url;
// export const Base_URL = 'http://65.2.9.81:81/';
// export const baseURL = 'http://localhost:3000/'
export const baseURL = 'https://partner.clubgrub.co/'